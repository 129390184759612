import Avial from "@ledr/ts-client";
import monaco from 'monaco-editor';

const keywords = [
	"COMMAND",
	"ERROR",
	"VERSION",
	"REPORT",
	"METHOD",
	"ATTRIBUTE",
	"EVENT",
	"MODE",
	"CATEGORY",
	"CLASS",
	"CONTEXT",
	"ASPECT",
	"STATE",
	"PRECEDENCE",
	"TAG",
	"CONDITION",
	"INSTANCE",
	"OFFSET",
	"TIME",
	"INDEX",
	"COUNT",
	"EXTENSION",
	"PARAMETER",
	"RESULTANT",
	"TIMEOUT",
	"ENTITY",
	"OUTLET",
	"AUXILIARY",
	"ANCILLARY",
	"AUTHORIZATION",
	"AUTHORITY",
	"NAME",
	"KEY",
];

let CommandNames = Object
	.keys(Avial.Command)
	.filter((command) => (!Number.isNaN(Number(Avial.Command[command]))))
	.map(name=> name.toLowerCase())
	.join("|");

let ReportNames = Object
	.keys(Avial.Report)
	.filter((report) => (!Number.isNaN(Number(Avial.Report[report]))))
	.map(name=> name.toLowerCase())
	.join("|");

let MethodNames     = Object.keys(Avial.Taxonomy.Method).map(name=> name.toLowerCase()).join("|");
let ContextNames    = Object.keys(Avial.Taxonomy.Context).map(name=> name.toLowerCase()).join("|");
let ClassNames      = Object.keys(Avial.Taxonomy.Class).map(name=> name.toLowerCase()).join("|");
let CategoryNames   = Object.keys(Avial.Taxonomy.Category).map(name=> name.toLowerCase()).join("|");
let AttributesNames = Object.keys(Avial.Taxonomy.Attribute).map(name=> name.toLowerCase()).join("|");
let EventNames      = Object.keys(Avial.Taxonomy.Event).map(name=> name.toLowerCase()).join("|");
let AspectNames     = Object.keys(Avial.Taxonomy.Aspect).map(name=> name.toLowerCase()).join("|");
let PrecedenceNames = Object.keys(Avial.Taxonomy.Precedence).map(name=> name.toLowerCase()).join("|");
let TagNames        = Object.keys(Avial.Taxonomy.Tag).map(name=> name.toLowerCase()).join("|");
let ModeNames       = Object.keys(Avial.Taxonomy.Mode).map(name=> name.toLowerCase()).join("|");
let StateNames      = Object.keys(Avial.Taxonomy.State).map(name=> name.toLowerCase()).join("|");
let ConditionNames  = Object.keys(Avial.Taxonomy.Condition).map(name=> name.toLowerCase()).join("|");
let ErrorNames      = Object.keys(Avial.Taxonomy.Error).map(name=> name.toLowerCase()).join("|");

export const completionHGTP = (monaco) =>
{
	const taxo = []
	taxo.push( {
		label: "SAVE",
		insertText:  "",
		description: `HGTP COMMAND`,
		kind: monaco.languages.CompletionItemKind.Method,
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
	})

	taxo.push( {
		label: "LOAD avial fetch",
		insertText:  "",
		description: `HGTP COMMAND`,
		kind: monaco.languages.CompletionItemKind.Method,
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
	})
	taxo.push( {
		label: "LOAD avial invoke",
		insertText:  "",
		description: `HGTP COMMAND`,
		kind: monaco.languages.CompletionItemKind.Method,
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
	})

	taxo.push( {
		label: "EXECUTE THIS COMMAND",
		insertText:  "",
		description: `HGTP COMMAND`,
		kind: monaco.languages.CompletionItemKind.Method,
		insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
	})
	// // // // // // // // // // // // // // // // // // // // // // // // //
	// // // // // // // // // // // // // // // // // // // // // // // // //
	// // // // // // // // // // // // // // // // // // // // // // // // //

	let ReportNames = Object
		.keys(Avial.Report)
		.filter((report) => (!Number.isNaN(Number(Avial.Report[report]))))
		.forEach(name=>
			{
					taxo.push({
						label: `REPORT ${name.toLowerCase()}`,
						insertText: `REPORT ${name.toLowerCase()}`,
						description: `HGTP REPORT`,
						kind: monaco.languages.CompletionItemKind.Method,
						insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
					})
			}
		)

	keywords.forEach(e => {
		taxo.push({
			label: e,
			insertText:  e,
			description: `HGTP COMMAND`,
			kind: monaco.languages.CompletionItemKind.Method,
			insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		})
	})

	Object.keys(Avial.Command).forEach( command => {
		if (Number.isNaN(Number(Avial.Command[command])))
		{
			taxo.push({
				label: `COMMAND ${Avial.Command[command].toLowerCase()}`,
				insertText:  `COMMAND ${Avial.Command[command].toLowerCase()}`,
				description: `HGTP COMMAND`,
				kind: monaco.languages.CompletionItemKind.Method,
				insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
			})
		}

	})

	Object.keys(Avial.Taxonomy).forEach( element => {
		Object.keys(Avial.Taxonomy[element]).forEach( item => {
			taxo.push({
				label: `${element.toUpperCase()} ${item.toLowerCase()}`,
				insertText:  `${element.toUpperCase()} ${item.toLowerCase()}`,
				description: `HGTP ${element}`,
				kind: monaco.languages.CompletionItemKind.Class,
				insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
			})
		})
	})


	return taxo;
}

///
/// REGEX ARE "GREEDY" BY DEFAULT
/// SET "LAZY" MODE ENABLED TO CATCH FULL WORD
/// (MATCH)      "HOST"
/// (BUT SHOULD) "HOSTel"
/// ON ENUMS
///

export const monarchHGTP:monaco.languages.IMonarchLanguage= {
	keywords: [],
	tokenizer:{
		root:[

			[/COMMAND/, 'keyword', '@commandEnum'],
			[/METHOD/, 'keyword', '@methodEnum'],
			[/CONTEXT/, 'keyword', '@contextEnum'],

			[/ATTRIBUTE/, 'keyword', '@attributeEnum'],
			[/CATEGORY/, 'keyword', '@categoryEnum'],
			[/CLASS/, 'keyword', '@classEnum'],

			[/EVENT/, 'keyword', '@eventEnum'],
			[/ASPECT/, 'keyword', '@aspectEnum'],
			[/PRECEDENCE/, 'keyword', '@precedenceEnum'],
			[/TAG/, 'keyword', '@tagEnum'],
			[/MODE/, 'keyword', '@modeEnum'],
			[/STATE/, 'keyword', '@stateEnum'],
			[/REPORT/, 'keyword', '@reportEnum'],

			[/CONDITION/, 'keyword', '@conditionEnum'],
			[/ERROR/, 'keyword', '@errorEnum'],

			[/NAME/, 'keyword', '@string'],
			[/KEY/, 'keyword', '@string'],

			[/ENTITY/, 'keyword', '@entity'],
			[/OUTLET/, 'keyword', '@entity'],
			[/AUXILIARY/, 'keyword', '@entity'],
			[/ANCILLARY/, 'keyword', '@entity'],
			[/OUTLET/, 'keyword', '@entity'],

			[/INSTANCE/, 'keyword', '@number'],
			[/OFFSET/, 'keyword', '@number'],
			[/INDEX/, 'keyword', '@number'],
			[/COUNT/, 'keyword', '@number'],
			[/PARAMETER/, 'keyword', '@number'],
			[/RESULTANT/, 'keyword', '@number'],
			[/TIMEOUT/, 'keyword', '@number'],
			[/TIME/, 'keyword', '@number'],
			[/VERSION/, 'keyword', '@number'],

			[/AUTHORIZATION/, 'keyword', '@authorization'],
			[/AUTHORITY/, 'keyword', '@authorization'],

			[/VALUE/, 'keyword', '@string'],

			[/.+/, 'type.other'],
		],


		number: [
			[/[0-9]+/, 'type.number', "@pop"  ], 
		],
		string: [
			[/".+"/, 'type.string', "@pop"  ], 
		],

		entity: [
			[/<([0-9]+)(\|[0-9]+)?(\|[0-9]+)?>/, 'type.entity', "@pop"  ],
		],
		authorization: [
			[/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g, 'type.auth', "@pop"],
		],

		commandEnum:    [ [new RegExp(CommandNames),    'type.enum', "@pop"] ],
		methodEnum:     [ [new RegExp(MethodNames),     'type.enum', "@pop"] ],
		contextEnum:    [ [new RegExp(ContextNames),    'type.enum', "@pop"] ],
		attributeEnum:  [ [new RegExp(AttributesNames), 'type.enum', "@pop"] ],
		categoryEnum:   [ [new RegExp(CategoryNames),   'type.enum', "@pop"] ],
		classEnum:      [ [new RegExp(ClassNames),      'type.enum', "@pop"] ],
		eventEnum:      [ [new RegExp(EventNames),      'type.enum', "@pop"] ],
		aspectEnum:     [ [new RegExp(AspectNames),     'type.enum', "@pop"] ],
		precedenceEnum: [ [new RegExp(PrecedenceNames), 'type.enum', "@pop"] ],
		tagEnum:        [ [new RegExp(TagNames),        'type.enum', "@pop"] ],
		modeEnum:       [ [new RegExp(ModeNames),       'type.enum', "@pop"] ],
		stateEnum:      [ [new RegExp(StateNames),      'type.enum', "@pop"] ],
		reportEnum:     [ [new RegExp(ReportNames),     'type.enum', "@pop"] ],
		conditionEnum:  [ [new RegExp(ConditionNames),  'type.enum', "@pop"] ],
		errorEnum:      [ [new RegExp(ErrorNames),      'type.enum', "@pop"] ],
	}
}

export function textToFrame(content: string){
	let NEW = {}
	content?.split(/(\r\n|\n|\r)/gm)?.map(e => {
		const regex = /\s+(?=(?:[^"]*"[^"]*")*[^"]*$)/g;
		let newContent = e?.replace(/\s+/g, ' ').trim().split(regex);
		newContent[0] = newContent[0]?.replace( /(\r\n|\n|\r)/gm, "");
		newContent[1] = newContent[1]?.replace( /(\r\n|\n|\r)/gm, "");
		if (newContent[0] === "NAME" || newContent[0] === "KEY" || newContent[0] === "VALUE" )
			newContent[1] = newContent[1]?.slice(1, newContent[1]?.length - 1);
		return newContent;

	}).filter((e)=> e[0].length !== 0 ).map(e => {
		if (e[0] && e[1] !== undefined)
		{
			if (typeof e[1] === "string" && e[0] !== "NAME" && e[0] !== "KEY" && e[0] !== "VALUE")
				NEW[e[0]] = e[1].toUpperCase();
			else if (
				e[0] ==="TIME" || e[0] === "INDEX" || e[0] === "COUNT" || 
				e[0] === "PARAMETER" || e[0] === "RESULTANT" || e[0] === "TIMEOUT"
			)
				NEW[e[0]] = BigInt(e[1]);
			else
				NEW[e[0]] = e[1];
		}
	})

	if (NEW["ENTITY"])
		NEW["ENTITY"] = new Avial.Values.V_Entity(NEW["ENTITY"]).value;
	if (NEW["OUTLET"])
		NEW["OUTLET"] = new Avial.Values.V_Entity(NEW["OUTLET"]).value;
	if (NEW["AUXILIARY"])
		NEW["AUXILIARY"] = new Avial.Values.V_Entity(NEW["AUXILIARY"]).value;
	if (NEW["ANCILLARY"])
		NEW["ANCILLARY"] = new Avial.Values.V_Entity(NEW["ANCILLARY"]).value;

	if (NEW["AUTHORIZATION"])
		NEW["AUTHORIZATION"] = new Avial.Values.V_Authorization(NEW["AUTHORIZATION"]).value;
	if (NEW["AUTHORITY"])
		NEW["AUTHORITY"] = new Avial.Values.V_Authorization(NEW["AUTHORITY"]).value;


	if (NEW["VALUE"])
	{
		try {
			let tmp = JSON.parse(NEW["VALUE"]);
			let tag = Object.keys(tmp)[0];

			if (tag === undefined)
				throw new Error("Invalid V_VALUE")

			let value = tmp[tag];
			NEW["VALUE"] = {};
			NEW["VALUE"].tag = Avial.Taxonomy.Tag[tag]
			NEW["VALUE"].data = new TextEncoder().encode(value);

		} catch(e) {
			console.log(e)

			NEW["VALUE"] = {
				tag: 0,
				data: new Uint8Array()
			} 
		}

	}

	let frame = new Avial.AtapiMessage();
	frame.fromObj(NEW)
	return frame;
}

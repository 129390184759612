



let frame = [
	{ name:"command",       length: 2 },
	{ name:"error",         length: 2 },
	{ name:"version",       length: 2 },
	{ name:"report",        length: 2 },
	{ name:"method",        length: 2 },
	{ name:"attribute",     length: 2 },
	{ name:"event",         length: 2 },
	{ name:"mode",          length: 2 },
	{ name:"category",      length: 2 },
	{ name:"class",         length: 2 },
	{ name:"context",       length: 2 },
	{ name:"aspect",        length: 2 },
	{ name:"state",         length: 2 },
	{ name:"precedence",    length: 2 },
	{ name:"tag",           length: 2 },
	{ name:"condition",     length: 2 },

	{ name:"instance",      length: 4 },
	{ name:"offset",        length: 4 },

	{ name:"time",          length: 8 },
	{ name:"index",         length: 8 },
	{ name:"count",         length: 8 },
	{ name:"extension",     length: 8 },
	{ name:"parameter",     length: 8 },
	{ name:"resultant",     length: 8 },
	{ name:"timeout",       length: 8 },

	{ name:"reserved",      length: 64 },

	{ name:"entity",        length: 16 },
	{ name:"outlet",        length: 16 },
	{ name:"auxiliary",     length: 16 },
	{ name:"ancillary",     length: 16 },
	{ name:"authorization", length: 16 },
	{ name:"authority",     length: 16 },

	{ name:"nameSize",      length: 1 },
	{ name:"name",          length: 255 },
	{ name:"keySize",       length: 1 },
	{ name:"key",           length: 255 },
	{ name:"byteSize",      length: 1 },
	{ name:"bytes",         length: 255 },
	{ name:"Extended",      length: 9999 }
];

let offset = 0;
const hgtpTemplate = frame.map((e,i)=> {
	offset += e.length;
	let color= `rgba(
		${Math.floor(Math.random() * 191) + 63},
		${Math.floor(Math.random() * 191) + 63},
		${Math.floor(Math.random() * 191) + 63}
		)`;
	return {
		...e,
		offset: offset - e.length,
		color: (template, buffer, offset, value) => color
	}
})


let offset2 = 0;
const colorValueTemplate = frame.map((e,i)=> {
	offset2 += e.length;
	let color= {
			r : Math.floor(Math.random() * 191) + 63,
			g : Math.floor(Math.random() * 191) + 63,
			b : Math.floor(Math.random() * 191) + 63
		};

	return {
		...e,
		offset: offset2 - e.length,
		color: (template, buffer, offset, value) => {
			const limit = template.offset + template.length;
			let isEmpty = true;
			for (let i = template.offset; i < limit; i++)
				if ( buffer[i] !== 0 )
				{
					isEmpty = false;
					i = limit;
				}

			return `rgba(
				${Math.floor( (isEmpty ? 0 : 1 ) * color.r)},
				${Math.floor( (isEmpty ? 0 : 1 ) * color.g)},
				${Math.floor( (isEmpty ? 0 : 1 ) * color.b)}
			)`;
		}
		//color: (value) => value ? color : `rgba(0,0,0,1)`
		//color: (value) => `hsl(${value / 255 * 360},50%,50%)`
	}
})



export default [
	{
		name: "value",
		template:colorValueTemplate 
	},
	{
		name: "hgtp",
		template: hgtpTemplate
	},
];

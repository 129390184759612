import React, { FunctionComponent } from 'react'
import "./laplacian.scss"
import {SubclassIcon} from '../three/components/Label';
import Matrix from '../../instruments/laplacian/matrix';

interface I_LaplacianProps {
	matrix: Matrix;
	legend: string[];
}

const Laplacian: FunctionComponent<I_LaplacianProps> = (props) => {

let m = props.matrix;


let max = m?.[0]?.[0] ?? 0;
let min = m?.[0]?.[0] ?? 0;

	m.forEach((e, x, y) => {
		if (e > max) max = e
		else if (e < min) min = e
	})






	return (
		<div className='laplacianGrid'>

			<div style={{display: 'flex'}}>
			<div className='cell'>
			</div >

			{
				props.matrix.m.map((line, i) => 
					<div className='cell'>
						<SubclassIcon class={props.legend[i]} size={30}/>
					</div>
			)
			}
			</div>

			{props.matrix.m.map((line, i) => 
			<>
				<div className='line'>

				<div className='cell'>
					<SubclassIcon class={props.legend[i]} size={25}/>
				</div>

					{line.map((cell,j)=> 
					<div className='cell' style={ {
						backgroundColor: cell ? `hsl(0,${(cell / max) * 100}%, 50%)`: "rgba(0,0,0,0.1)",
						border: (i === j) ? "1px solid white" : "1px solid black"
						} }>
						{cell}
					</div>
					)}
				</div>
			</>
			)}
		</div>
	)
}

export default Laplacian;

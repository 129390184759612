export default [
	{
		name:"Retrieve",
		status: "",
		content:
`COMMAND       invoke
METHOD        retrieve
ENTITY        <1>       // CAN BE <0|0|1> OR <1> AS SHORTCUT
`
	},
	{
		name:"Connect",
		status: "",
		content:
`COMMAND       connect
ENTITY        <888>
OUTLET        <888>       // CAN BE <0|0|1> OR <1> AS SHORTCUT
PRECEDENCE    avesterra
AUTHORIZATION c9c552fb-cfdb-43d5-b767-1fa787287231
`
	},
	{
		name:"Purge",
		status: "",
		content:
`COMMAND       invoke
METHOD        purge
ASPECT        property

ENTITY        <25>       // CAN BE <0|0|1> OR <1> AS SHORTCUT
AUTHORIZATION c9c552fb-cfdb-43d5-b767-1fa787287231
`
	},
	{

		name: "Metadata",
		status: "",
		content:
`// CHANGE is used to edit an entity's metadata
COMMAND       change
ENTITY        <25>       // CAN BE <0|0|1> OR <1> AS SHORTCUT

// NAME
// KEY

// CONTEXT
// CATEGORY
// CLASS
// STATE

// AUTHORITY
`
	},
	{
		name: "full",
		status: "",
		content:
		`// AVU can contain comments
COMMAND       invoke
METHOD        attribute

NAME          "Super Name"
KEY           "Super Key"

ATTRIBUTE     abstract
CATEGORY      airplane
CLASS         hospital
CONTEXT       avesterra

ENTITY        <0|0|1>
OUTLET        <24>
AUXILIARY     <10|10>
ANCILLARY     <0>
AUTHORIZATION 7e6b5eb2-7a34-4dce-85d6-fe3d5bf23a4b
AUTHORITY     7e6b5eb2-7a34-4dce-85d6-fe3d5bf23a4b

EVENT         alert
MODE          interchange

ASPECT        ancillary

STATE         good
PRECEDENCE    graph
CONDITION     holding

INSTANCE      1
OFFSET        2

// ALL THESES ARE BIGINT
// TIME          3
// INDEX         4
// COUNT         5
// PARAMETER     6
// RESULTANT     7
// TIMEOUT       8

REPORT        active
ERROR         application
VERSION       4
// TAG           interchange // NO NEED IF VALUE PROVIde IT
`
	}
];

import { FunctionComponent, useState, useEffect } from "react";
import { useCallback } from "react";
import { Handle, Position } from "react-flow-renderer";
import "./selectNode.scss";
import windows from "../index";
import { Icon } from "@ledr/instruments";

interface SelectNodeProps {}
const SelectNode: FunctionComponent<SelectNodeProps> = (props) => {
  const [instruments, setInstruments] = useState(
    //@ts-ignore
    props.data?.category?.content ?? []
  );

  //@ts-ignore
  const [name, setName] = useState(props.data?.category?.name ?? "");

  const addInstrument = (value) => {
    setInstruments([...instruments, value]);
  };

  const deleteInstrument = (i) => {
    console.log("CLICK DELETE");
    setInstruments([
      ...instruments.slice(0, i),
      ...instruments.slice(i + 1, instruments.length),
    ]);
  };

  useEffect(() => {
    //@ts-ignore
    props.data.onChangeCategory({
      name: name,
      content: instruments,
    });
  }, [name, instruments]);
  return (
    //@ts-ignore
    <div className={`flowNode ${props.selected ? "selected" : ""}`}>
      <div className="nodeHeader">Category</div>
      <div className="nodeContent">
        <input
          style={{ width: "100%" }}
          value={name}
          type="text"
          onChange={(e) => setName(e.target.value)}
          placeholder={"Name"}
        />
        <div style={{ padding: "10px 10px" }}>
          {instruments.map((e, i) => {
            const c = {
              r: (Math.random() * 255).toFixed(0),
              g: (Math.random() * 255).toFixed(0),
              b: (Math.random() * 255).toFixed(0),
            };
            return (
              <div key={i} className="instrumentLine">
                <Icon name={windows[e].icon} style={{ marginBottom: "-2px" }} />
                <span style={{ paddingLeft: "10px", width: "200px" }}>{e}</span>
                <div className={"icon"} onClick={() => deleteInstrument(i)}>
                  <Icon
                    name={"MdOutlineCancel"}
                    style={{ marginLeft: "5px", marginBottom: "-2px" }}
                  />
                </div>
                <span
                  style={{
                    float: "right",
                    width: "15px",
                    height: "15px",
                    backgroundColor: `rgba(${c.r},${c.g},${c.b},1)`,
                    display: "inline-block",
                    border: "1px solid white",
                    marginLeft: "5px",
                  }}
                ></span>
                <button
                  style={{
                    float: "right",
                    height: "20px",
                    marginTop: "-2.5px",
                    marginLeft: "20px",
                  }}
                >
                  Opts
                </button>
              </div>
            );
          })}
        </div>
        <div>
          <select
            style={{ width: "100%" }}
            id="pet-select"
            value=""
            onChange={(e) => addInstrument(e.target.value)}
          >
            <option value="">- Add instrument -</option>
            {Object.entries(windows).map(([name, value], i) => {
              return (
                <option key={i} value={name}>
                  {value.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <Handle type="source" position={Position.Right} id="outputb" />
    </div>
  );
};

export default SelectNode;

//@ts-nocheck
import { FunctionComponent, useState } from "react";
import Fold from "./Fold";
import "./Loading.scss";

interface LoadingProps {
	isLoading: boolean;
	children: JSX.Element;
	message?: string;
}

const Loading: FunctionComponent<LoadingProps> = (props) => {
  return !props.isLoading
		? <>{props.children}</>
		: <div className="Loading">
			<p>
					{props.message || ""}
			</p>
				<svg className="spinner" viewBox="0 0 50 50">
					<circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"/>
				</svg>
			</div>
};

export default Loading;

import React, { FunctionComponent } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store/types";
import "./Authorizations.scss";

interface AuthorizationsProps {

}

const Authorizations: FunctionComponent<AuthorizationsProps> = (props) => {
	const keychain = useSelector((state: AppState) => state.user.keychain.accesses);

	return (
		<div
			className={"Authorizations"}
			style={{
				display: "grid",
				gridTemplateColumns: "auto auto auto auto auto "
		}}>

			<div className='head' >Name</div>
			<div className='head'>Auth</div>
			<div className='head'>Server IP</div>
			<div className='head'>Gateway</div>
			<div className='head'>Delete</div>
			
			{keychain.map(e => 
				<>
					<div>
						{e.compartment}
					</div>
					<div>
						{e.token}
					</div>
					<div>
						- -
					</div>
					<div>
						{"<"}.|.|.{">"}
					</div>
					<button>
						X
					</button>
				</>
			)}
		</div>
	)
}

export default Authorizations;

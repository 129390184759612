import {
	SetKeyBindingAction, SET_KEY_BINDING,
	ChangeKeybindingAction, CHANGE_KEY_BINDING,
} from "./types";

export const setKeyBindingAction = (key:string, action:string, eventType:string):SetKeyBindingAction => ({
	type: SET_KEY_BINDING,
	key,
	action,
	eventType,
});

export const changeKeyBindingAction = (key:string, action:string, eventType:string):ChangeKeybindingAction => ({
	type: CHANGE_KEY_BINDING,
	key,
	action,
	eventType,
});

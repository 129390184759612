import { FunctionComponent } from "react";
import {iconClass, iconClassColor} from "../../../style/icons";
import { Icon } from "@ledr/instruments"
import "./Label.scss"

interface ClassIconProps {
	class: string | number;
	size?: number;
}
export const SubclassIcon: FunctionComponent<ClassIconProps> = (props) => {
	let gen =  `hsl(${iconClassColor(props.class ?? "")} 100% 40%)`;
	let opts = {
		size: props.size ?? 45,
		style: {
			//backgroundColor: gen,//`hsl(gen{Math.floor(Math.random() * 360)}, 80%, 50%)`,//,iconClassColor[props.class] ?? "white",//"#86de74",//rgba(0,0,0,0.6)",
			//color: "black",
			color: gen,//`hsl(gen{Math.floor(Math.random() * 360)}, 80%, 50%)`,//,iconClassColor[props.class] ?? "white",//"#86de74",//rgba(0,0,0,0.6)",
			backgroundColor: "rgba(16,16,16,1)",
			margin: "auto",
			borderRadius: "100%",
			padding: "3px",
		},
	};

	let unknow = {
		size: opts.size,
		style: {
			...opts.style,
			color: "red"
		},
	};

	return iconClass[props.class]
		? <Icon name={iconClass[props.class]} {...opts} />
		: <Icon name={"MdError"} {...unknow} />;
};

interface LabelProps {
	data: any;
}


const Label: FunctionComponent<LabelProps> = (props) => {
	return (
		<div className="label" >
			<div className={"icon"} >
				<SubclassIcon class={ props.data.class } />
			</div>


			<div className={"textBox"} >

				<div className={"text"}
					style={{
						backgroundColor: `hsl(${iconClassColor( props.data.class ?? "")} 100% 40%)`,
					}}
				>
					{ props.data.name }
				</div>
			</div>
		</div>

	);
};

export default Label;


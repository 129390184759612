import { FunctionComponent, useState, useEffect, useMemo, useContext } from "react";
import { Print, WindowChild, } from "@ledr/instruments";
import  InstrumentsLinkerContext from "../context/instrumentsLinker";
import  InstrumentsLinkerOverseerContext from "../context/instrumentsLinkerOverseer";
import './EntityLinkerDebug.scss'

interface StoreProps {}
const Store: FunctionComponent<StoreProps> = (props) => {
	const InstrumentsLinkerOverseer = useContext(InstrumentsLinkerOverseerContext); 
	const MyContext = useContext(InstrumentsLinkerContext); 

	const [nodeIn, setEntityClass] = useState();

	useEffect(()=>{
		// DECLARE HIMSELF TO UPPER STAGE
		MyContext.declareInOut(
			{
				in : [
					{port: "entityClass", setter: setEntityClass},
				],
				out :[
				//	{port: "someOutPort" },
				]
			}
		)	
		return () => { MyContext.unDeclareInOut() }
	}, [])

	const tab = useMemo( () => (<> Instrument Selector</>), []);
	const win = useMemo(() => (
		<>
			INSTRUMENT SELECTOR (todo: look after instrument linker to see if there isn't better way to handle it)	
		</>
	), [MyContext]);


	return <WindowChild tab={tab}>{win}</WindowChild>;
};

export default Store;

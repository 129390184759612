import { FunctionComponent, useState, useEffect } from "react";
import { useCallback } from "react";
import { Handle, Position } from "react-flow-renderer";
import "./selectNode.scss";
import windows from "../index";
import { Wm, Tooltip, Modal, Icon, IconSelector } from "@ledr/instruments";
import FakeWin from "./FakeWin";

interface PanelButtonProps {
  name: string;
  icon: string;
  isActive: boolean;
  onChange: () => void;
}

const PanelButton: FunctionComponent<PanelButtonProps> = (props) => {
  return (
    <div>
      <Tooltip text={props.name} position={"bottom"}>
				{/*
				// @ts-ignore */}
        <button
          style={{ padding: "10px", height: "50px" }}
          className={props.isActive ? "active" : ""}
          onClick={props.onChange}
        >
          <Icon name={props.icon} size={30} />
        </button>
      </Tooltip>
    </div>
  );
};

interface PanelProps {}
const Panel: FunctionComponent<PanelProps> = (props) => {
  // @ts-ignore
  const [panel, setPanel] = useState(props.data.panel);
  // @ts-ignore
  useEffect(() => setPanel(props.data.panel), [props.data.panel]);

  const [iconModal, setIconModal] = useState(false);
  const [preview, setPreview] = useState(false);
  const [addWin, setAddWin] = useState(false);
  const [iconName, setIconName] = useState("");

  let newWin = {};
  Object.entries(windows).forEach((e, v) => {
    newWin[e[0]] = {
      ...e[1],
      component: () => <FakeWin panel={panel} win={e[1]} />,
    };
  });

  let screenWidth = 2560;
  let screenHeight = 1440;
  let scale = 0.25;

  let screenWidthMin = screenWidth * scale + (screenWidth * scale) / 2;
  let screenHeightMin = screenHeight * scale + (screenHeight * scale) / 2;

  let styleA = {
    width: `${screenWidth * scale}px`,
    height: `${screenHeight * scale}px`,
  };
  let styleB = {
    width: `${screenWidth}px`,
    height: `${screenHeight}px`,
    transform: `translate(-${screenWidthMin}px, -${screenHeightMin}px) scale(${scale})`,
  };

  return (
    //@ts-ignore
    <div className={`flowNode ${props.selected ? "selected" : ""}`}>
      <div className="nodeHeader">Panel</div>
      <div className="nodeContent">
        <Modal show={iconModal} close={() => setIconModal(false)}>
				{/*
				// @ts-ignore */}
          <IconSelector
            color={"white"}
            number={55}
            onSelect={(e) => {
              setIconName(e);
              //@ts-ignore
              props.data.onChange({ icon: e });
              setIconModal(false);
            }}
          />
        </Modal>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <button
            style={{
              width: "70px",
              height: "70px",
              padding: "10px",
            }}
            onClick={() => setIconModal(true)}
          >
            <Icon name={panel.icon} size={50} />
          </button>

          <input
            style={{
              width: "100%",
              height: "50px",
              margin: "10px 10px",
              fontSize: "30px",
            }}
            value={panel.name}
            type="text"
            onChange={(e) => {
              //setName(e.target.value);
              //@ts-ignore
              props.data.onChange({ name: e.target.value });
            }}
            placeholder={"Name"}
          />
        </div>

        <div
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <PanelButton
            name={"Debug"}
            icon={"AiFillBug"}
            isActive={panel.options.debug}
            onChange={() =>
              //@ts-ignore
              props.data.onChange({
                options: { debug: !panel.options.debug },
              })
            }
          />
          <PanelButton
            name={"New Win"}
            icon={"MdAddCircleOutline"}
            isActive={addWin}
            onChange={() => setAddWin(!addWin)}
          />
          <PanelButton
            name={"Preview"}
            icon={"MdPreview"}
            isActive={preview}
            onChange={() => setPreview(!preview)}
          />
          <PanelButton
            name={"Show windows tabs"}
            icon={"RiWindowFill"}
            isActive={!panel.options.hideWinTabs}
            onChange={() =>
              //@ts-ignore
              props.data.onChange({
                options: { hideWinTabs: !panel.options.hideWinTabs },
              })
            }
          />
          <PanelButton
            name={"Show windows pads"}
            icon={"BiJoystickButton"}
            isActive={panel.options.showPad}
            onChange={() =>
              //@ts-ignore
              props.data.onChange({
                options: { showPad: !panel.options.showPad },
              })
            }
          />

          <PanelButton
            name={"Is resizeable"}
            icon={"BiExpand"}
            isActive={panel.options.twmResizeable}
            onChange={() =>
              //@ts-ignore
              props.data.onChange({
                options: { twmResizeable: !panel.options.twmResizeable },
              })
            }
          />
        </div>
        {/*
				<div>
					{screenWidth}*{screenHeight} Forced
				</div>
					*/}

        <div
          style={
            preview
              ? styleA
              : {
                  width: "640px",
                  height: "360px",
                }
          }
        >
          <div
            style={
              preview
                ? styleB
                : {
                    width: "100%",
                    height: "100%",
                  }
            }
          >
            <Wm
              //@ts-ignore
              tilingWindows={panel.twmArbo}
              //@ts-ignore
              floatingWindows={panel.fwmWindows}
              windows={preview ? windows : newWin}
              //@ts-ignore
              categories={props.data.categories}
              options={{
                ...panel.options,
                showNewWindow: addWin,
              }}
              onChange={(twmArbo, floatingWindows) => {
                setAddWin(false);
                //@ts-ignore
                props.data.onChange({
                  //@ts-ignore
                  ...props.data.panel,
                  twmArbo: twmArbo,
                  fwmWindows: floatingWindows,
                });
              }}
            />
          </div>
        </div>
      </div>

      <Handle type="target" position={Position.Left} id="input" />
    </div>
  );
};

export default Panel;

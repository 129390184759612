import Avial, {AvialType} from "@ledr/ts-client";
import React, { FunctionComponent, useState } from 'react'
import {EntityInput, SearchSelect} from "@ledr/instruments";
import Vector from "./Vector";

interface newPointProps {
	dimentions: any;
	center: any;
	onCancel: () =>void;
	onCreate: (entity, name, coordinate, klass, category) =>void;
}

const newPoint: FunctionComponent<newPointProps> = (props) => {
	const [name, setName] = useState("");
	const [category, setCategory] = useState("");
	const [klass, setKlass] = useState("");
	const [entity, setEntity] = useState(
		new Avial.Values.V_Entity("<0|0|0>")
	);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				padding: "10px"
			}}
		>

			<input
				type={"text"}
				placeholder={"Name"}
				value={name}
				onChange={(e) => setName(e.target.value)}
			/>

			<br/>

			<div style={{width:"200px"}}>
				<EntityInput value={entity} onChange={setEntity} />
			</div>

			<Vector
				name={"position"}
				dimentions={props.dimentions}
				vector={props.center}
			/>

			<br/>

			<div>
				<SearchSelect
					value={category}
					list={Avial.Taxonomy.Category}
					onChange={setCategory}
					placeholder={"Category"}
				/>
			</div>

			<div>
				<SearchSelect
					value={klass}
					list={Avial.Taxonomy.Class}
					onChange={setKlass}
					placeholder={"Class"}
				/>
			</div>

			<div>
				<button onClick={() => props.onCancel()}>Cancel</button>
				<button onClick={() => props.onCreate(
					entity,
					name,
					props.center,
					Avial.Taxonomy.Category[category],
					Avial.Taxonomy.Class[klass]
				)}>Create</button>
			</div>

		</div>
	)
}

export default newPoint;

import { FunctionComponent, useState } from "react";
import {MouseEventHandler} from 'react';

interface MouseProps{
	drag?: (x:number,y:number)=>void;
	onClick?: MouseEventHandler;
	onContextMenu?: ()=>void;
	onWheel?: MouseEventHandler;
	hoverIn?: MouseEventHandler;
	hoverOut?: MouseEventHandler;
}

const Mouse: FunctionComponent<MouseProps> = (props) => {
	const [isMouseOver, setIsMouseOver] = useState(false)

	function startDragging(evt){
		let mouseLastPos = [evt.clientX, evt.clientY];

		let mvt = (evt) => {
			let deltaX = evt.x - mouseLastPos[0];
			let deltaY = evt.y - mouseLastPos[1];
			mouseLastPos[0] = evt.clientX;
			mouseLastPos[1] = evt.clientY;
			props.drag(deltaX , deltaY);
		};

		let up = ()=> {
			window.removeEventListener("mousemove", mvt);
			window.removeEventListener("mousemove", up);
		}

		window.addEventListener( "mousemove", mvt, false);
		window.addEventListener( "mouseup", up, false);

		evt.preventDefault();
		evt.stopPropagation();
	}

	// let Children = props.children;
	return <div
		style={{
			cursor:"pointer",
			pointerEvents:"all"
		}}

		onWheel={ props.onWheel }

		onMouseDown={startDragging}
		onClick={ props.onClick }
		onContextMenu={
			props.onContextMenu
		}

		onMouseEnter={(evt)=> {
			setIsMouseOver(true)
			props.hoverIn(evt)
		}}
		onMouseLeave={(evt)=>{
			setIsMouseOver(false)
			props.hoverOut(evt)
		}}
	>
		{props.children}
		{/*
	<
	//@ts-ignore
		Children {...{
		isHovered: isMouseOver
	}}
	/>
			*/}
		</div>
}

export default Mouse;

import { Animate } from './init';

interface I_Style{
	name:    string;
	set:     () => void;
	remove:  () => void;
	animate: Animate;
}

// CASCADING TRICKY 
// IF YOU WANT TO DESACTIVATE A STYLE 

class Style {
	stack: I_Style[] = [];
	activated: I_Style[] = [];

	constructor(styles?: I_Style[]) {
		if (styles)
			this.stack = styles;
	}

	activate(styleName: string) {
		
	 if (this.activated.find(s => s.name === styleName))
			return;

		let newStyle = this.stack.find(s => s.name === styleName);
		if (!newStyle)
		{
			console.log(`ERROR ${styleName} not found`)
			return;
		}

		this.activated.push(newStyle);
		newStyle.set();
		// no need to reactivate ALL
		//this.activated.forEach(s => s.set())
	}

	desactivate(styleName: string){
		let finded = this.activated.find(s => s.name === styleName)
		 if (!finded)
				return;

		finded.remove();

		this.activated = this.activated.filter(s => s.name !== styleName)
		// SET CAN LEAKS BY GENERATING MULTIPLES TIME EXISTING STUFF
		// SET CAN LEAKS BY GENERATING MULTIPLES TIME EXISTING STUFF
		//this.activated.forEach(s => s.set())
		// SET CAN LEAKS BY GENERATING MULTIPLES TIME EXISTING STUFF
		// SET CAN LEAKS BY GENERATING MULTIPLES TIME EXISTING STUFF
	}

	registerStyle(style: I_Style | I_Style[]) {
		if (style.constructor === Array)
			this.stack.push(...style)
		else
			//@ts-ignore
			this.stack.push(style)
	}

	deleteStyle(style: I_Style) {
		this.stack.push(style)
		this.stack.splice(
			this.stack.findIndex(s => s.name === style.name),
			1
		);
	}

	animate(d){
		this.activated.forEach(s => s.animate(d))
	}

}


export {I_Style};
export default Style;

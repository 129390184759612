import React, { FunctionComponent, useState } from "react";
import { Categories } from "@ledr/instruments";
import "./FakeWin.scss";
import { I_Panels } from "../../store/panels/reducer";
import { Icon } from "@ledr/instruments";

interface FakeWinProps {
  win: any;
  panel: I_Panels;
}
const FakeWin: FunctionComponent<FakeWinProps> = (props) => {
  return (
    <div className="fakewin">
      {!props.panel.options.hideWinTabs && (
        <div className={"tab"}>{props.win.name}</div>
      )}
      <div className="ico">
        <div>
          <Icon name={props.win.icon} size={40} />
          {props.panel.options.hideWinTabs && <div>{props.win.name}</div>}
        </div>
      </div>
    </div>
  );
};

export default FakeWin;

import React from "react";


interface InstrumentLinkerContext {
			show: boolean;

			setNewLink: (any) => void;
			setNewPorts: any;
			unsetPorts:  any;

			declareRef: any;
			removeRef: () => any;

			allLinks: any;
			allPorts: any;
			links: any;
			setCached:( winId, key, data )=>void;
			cached:object;
/*
			setNewLink: (link) => setAllLinks([...allLinks, link]),
			setNewPorts: setPorts,
			unsetPorts:  unsetPorts,

			declareRef: setRef,
			removeRef: () => setAllRef([]),

			allLinks: allLinks,
			allPorts: allPorts
 */
}

const InstrumentsLinkerOverseerContext: React.Context<InstrumentLinkerContext> = React.createContext(undefined);

export default InstrumentsLinkerOverseerContext;

import React, { FunctionComponent, useEffect, useContext, useState } from 'react'
import  InstrumentsLinkerOverseerContext from "../../context/instrumentsLinkerOverseer";
import "./InstrumentLinkerSvgPath.scss"
import useKeyboard from '../../hook/keyboard';
import { Print, avesterraDrawer  } from "@ledr/instruments";

//@ts-expect-error
const requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;

//@ts-expect-error
const cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;

let req;

interface SvgLinksProps {
	allLinks: any;
	allRef: any;
	setSelected: (n: number) => void;
	selected: number;
	deleteLink:(id: number) => void; 
}

const SvgLinks: FunctionComponent<SvgLinksProps> = (props) => {
	const InstrumentsLinkerOverseer = useContext(InstrumentsLinkerOverseerContext); 
	let [linksStroke, setLinksStroke]  = useState([]);

	useEffect(() => {
		animate()
		return ()=>{ cancelAnimationFrame(req); }
	}, []);

	useEffect(() => {
		cancelAnimationFrame(req);
		requestAnimationFrame(animate);
	}, [props.allLinks ]);


	const animate = () => {
		req = requestAnimationFrame(animate);

		let linksStroke = props.allLinks?.map((link, id) => {
			const {winId: outWinId, port: outPort} = link.outNode;
			const {winId: inWinId, port: inPort} = link.inNode;

			let start = props.allRef.find(r => r.winId === outWinId && r.port === outPort && r.type === "out")?.ref;
			let end = props.allRef.find(r => r.winId === inWinId && r.port === inPort && r.type === "in" )?.ref;

			start = start?.getBoundingClientRect?.();
			end = end?.getBoundingClientRect?.();

			// STORE START/END POS THEN REFRESH ONLY IF NECESSARY
			// TO AVOID SPAMMING RERENDER

			let threshold = Math.max(start?.width, end?.width);

			// START
			let y1 = start?.top + (start?.height / 2);
			let h1y = y1;
			let x1 = start?.left;

			// END
			let y2 = end?.top + (end?.height / 2);
			let h2y = y2;
			let x2 = end?.left;

			// MUST WRITE F(delta(x1,x2), delta(y1, y2)) THAT CURVE NICER
			// it should take y as parameter tool
			// since a long link must be more curved than short one

			// short delta on Y smaller handle | X/Y less overlap
			let deltaY = Math.abs(y1 - y2) ;

			let handlePower = Math.abs(x1 - x2) / 2 + (deltaY / 8) + 40;
			//if (handlePower < 50) handlePower = 50;

			let h1x = x1 - handlePower;
			let h2x = x2 - handlePower;

			if (start?.left < end?.left)
			{
				x1 =  start?.left + start?.width ;
				h1x = x1 + handlePower;

				if (start?.left < end?.left + threshold && start?.left > end?.left - threshold )
				{
					x2 =  end?.left + end?.width ;
					h2x = x2 + handlePower;
				}

			} else {
				if (start?.left > end?.left + threshold || start?.left < end?.left - threshold )
				{
					x2 =  end?.left + end?.width ;
					h2x = x2 + handlePower;
				}
			}

			return ({
				id,
				link,
				x1,  y1,
				h1x, h1y,
				h2x, h2y,
				x2,  y2
			})

		})

		setLinksStroke(linksStroke)
	};

let selected = linksStroke[props.selected];

	return (
		<>

		{
			selected &&
				<div
					style={{
						position: "absolute",
						zIndex:"999999",
						overflow:"scroll",
						top: `${selected.h1y + ((selected.h2y - selected.h1y) / 2)}px`,
						left: `${selected.h1x + ((selected.h2x - selected.h1x) / 2)}px`,
						marginTop: '-100px',
						marginLeft: '-200px',
						width:"400px",
						height:"200px",
						backgroundColor: "rgba(0,0,0,0.8)",
						textAlign: "center"
					}}
				>

					<button
						style={{
							padding: "5px",
								backgroundColor: "rgba(255,0,0,0.4)"
						}}
						onClick={()=>{
						props.deleteLink(selected.id)
						props.setSelected(null)
						}}>
						delete Link
					</button>
					<br/>
					[{selected.link.outNode.winId}]
					{" "}
					[{selected.link.outNode.port}]
					<br/>
					#ID [{selected.id}]



					<hr/>
					ACTUAL VALUE IN LINK : 
					<br/>
					{/*
					<Print value={
							InstrumentsLinkerOverseer.cached[
								selected.link.outNode.winId +
								selected.link.outNode.port
							]
						}
					/>
					*/}

											<Print
												value={
													InstrumentsLinkerOverseer.cached[
														selected.link.outNode.winId +
														selected.link.outNode.port
													]
												}
												drawer={avesterraDrawer}
											/> 
					<hr/>

					<Print value={InstrumentsLinkerOverseer}
					/>

				</div>
		}

		<svg style={{
			position: "absolute",
				zIndex: "3000",
				pointerEvents: "none",
				top: "0px", left: "0px",
				width: "100vw", height: "100vh"
			}}>

			{
				linksStroke.map((l, i) =>

					<React.Fragment key={i}>
						<path
							onClick={() => props.setSelected(i)}
							className={`clickableStroke`}
							d={`M ${l.x1} ${l.y1} C ${l.h1x} ${l.h1y} ${l.h2x} ${l.h2y} ${l.x2} ${l.y2}`}
						/>
						<path
							onClick={() => props.setSelected(i)}
							className={`animatedStroke ${props.selected === i ? 'sel' : ''}`}
							d={`M ${l.x1} ${l.y1} C ${l.h1x} ${l.h1y} ${l.h2x} ${l.h2y} ${l.x2} ${l.y2}`}
						/>
					</React.Fragment>
				)
			}
		</svg>
		</>);
}

interface InstrumentLinkerSvgPathProps {
	allLinks: any;
	allRef: any;
	deleteLink:(id: number) => void; 
}
const InstrumentLinkerSvgPath: FunctionComponent<InstrumentLinkerSvgPathProps> = (props) => {
	let [selected, setSelected]  = useState<number>();
	let [animateLoop, setAnimateLoop]  = useState();

	useKeyboard([ { action: "deleteLink", cb:
		() => {
			if (selected !== undefined)
			{
				props.deleteLink(selected)
				setSelected(undefined)
			}
		}
	}, ]);

	return (
		<>
			{props.allLinks &&
			<SvgLinks
				allLinks={props.allLinks}
				allRef={props.allRef}
				selected={selected}
				setSelected={setSelected}
				deleteLink={props.deleteLink}
			/>
			}
		</>
	)
}

export default InstrumentLinkerSvgPath;

import React, { FunctionComponent } from 'react'

import { AvialType } from '@ledr/ts-client'
import {Print} from '@ledr/instruments';

interface LocutorProps {
	value: AvialType.Locutor;

}

const Locutor: FunctionComponent<LocutorProps> = (props) => {

	return (
		<div style={{
			display:"grid",
				gridTemplateColumns: "min-content 1fr",
			}}>
			{
				Object.entries(props.value.value)
				.filter(([k,v]) => v !== null && k !== "TAG")
				.map(([k,v]) =>
					<>
						<div style={{
							color:"#75bfff",
								textAlign:"right",
								marginRight:"5px"
							}}>
							{k}
						</div>
						<div style={{ color:"#86de74" }}>
							{
								//@ts-ignore
								k === "ASPECT" && v.slice(0, -7)}
							{
								//@ts-ignore
								k === "ATTRIBUTE" && v.slice(0, -10)}
							{
								k !== "ASPECT" && k !== "ATTRIBUTE" && v}
						</div>
					</>
				)
			}
		</div>
	)
}

export default Locutor;

import * as THREE from 'three'
import Avial, { AvialType } from '@ledr/ts-client'
import { ThreeInit } from '../init'
import MouseMenu from './MouseMenu'

// STOPPER FORCE SIMULATION PENDANT SELECTION (rien ne doit bouger)
// REPRENDRE SIMULATION QUAND BOUTON SOURIE RELACHE
// ADAPTER CUBE A "BOUNDINGS" DE LA SELECTION
class MouseSelect extends THREE.Mesh {
	three: ThreeInit
	camera: THREE.PerspectiveCamera
	pointMap: {}
	lines: THREE.LineSegments
	MouseMenu: MouseMenu

	constructor(three: ThreeInit, camera: THREE.PerspectiveCamera) {
		const geometry = new THREE.BufferGeometry()
		const material = new THREE.LineBasicMaterial({
			side: THREE.DoubleSide,
			color: 0xffffff,
			linewidth: 1,
			vertexColors: true,
			toneMapped: false,
			//wireframe: true,
			transparent: true,
			opacity: 0.1,
		})
		geometry.setIndex([
			0,
			1,
			2,
			2,
			3,
			0, // near
			4,
			6,
			5,
			5,
			6,
			7, // far
			0,
			4,
			1,
			4,
			5,
			1, // bottom
			3,
			2,
			7,
			6,
			3,
			7, // top
			6,
			4,
			3,
			3,
			4,
			0, // left
			5,
			7,
			2,
			1,
			5,
			2, // right
		])

		const vertices = []
		const colors = []

		const pointMap = {}
		function addPoint(id) {
			if (pointMap[id] === undefined) {
				pointMap[id] = []
				vertices.push(0, 0, 0)
				colors.push(0, 0, 0)
			}
			pointMap[id].push(vertices.length / 3 - 1)
		}

		function addLine(a, b) {
			addPoint(a)
			addPoint(b)
		}

		// near
		addLine('n1', 'n2')
		addLine('n2', 'n4')
		addLine('n4', 'n3')
		addLine('n3', 'n1')

		// far
		addLine('f1', 'f2')
		addLine('f3', 'f1')
		addLine('f2', 'f4')
		addLine('f4', 'f3')

		// sides
		addLine('n1', 'f1')
		addLine('n2', 'f2')
		addLine('n3', 'f3')
		addLine('n4', 'f4')

		geometry.setAttribute(
			'position',
			new THREE.Float32BufferAttribute(vertices, 3)
		)
		geometry.setAttribute(
			'color',
			new THREE.Float32BufferAttribute(colors, 3)
		)

		super(geometry, material)

		this.three = three

		this.pointMap = pointMap

		this.camera = camera
		this.camera.updateProjectionMatrix?.()
		this.matrix = camera.matrixWorld.clone()
		this.matrixAutoUpdate = false

		// colors
		this.setColors(new THREE.Color(0xffffff))

		this.lines = new THREE.LineSegments(
			geometry,
			new THREE.LineBasicMaterial({ color: 0xffffff, vertexColors: true })
		)
		this.add(this.lines)

		this.MouseMenu = new MouseMenu(this.three)
		this.add(this.MouseMenu)
		this.update(new THREE.Vector2(1, 1), new THREE.Vector2(1, 1))
	}

	setColors(color) {
		const geometry = this.geometry
		const colorAttribute = geometry.getAttribute('color')

		for (let i = 0; i < geometry.attributes.position.count; i++) {
			colorAttribute.setXYZ(i, color.r, color.g, color.b)
		}
		colorAttribute.needsUpdate = true
	}

	update(mouseStart: THREE.Vector2, mouse: THREE.Vector2) {
		let camera = this.camera.clone()

		camera.near = camera.position.z - 0.1
		camera.far = camera.position.z + 0.1

		camera?.updateProjectionMatrix?.()
		const _camera = /*@__PURE__*/ new THREE.Camera()
		_camera.projectionMatrixInverse.copy(camera.projectionMatrixInverse)

		this.matrix = camera.matrixWorld
		this.matrixAutoUpdate = false

		const w1 = mouseStart.x,
			h1 = mouseStart.y,
			w2 = mouse.x,
			h2 = mouse.y

		// near
		this.setPoint('n1', _camera, w1, h1, -1)
		this.setPoint('n2', _camera, w2, h1, -1)
		this.setPoint('n3', _camera, w1, h2, -1)
		this.setPoint('n4', _camera, w2, h2, -1)
		// far
		this.setPoint('f1', _camera, w1, h1, 1)
		this.setPoint('f2', _camera, w2, h1, 1)
		this.setPoint('f3', _camera, w1, h2, 1)
		this.setPoint('f4', _camera, w2, h2, 1)

		this.geometry.computeBoundingBox()
		this.geometry.computeBoundingSphere()
		this.geometry.getAttribute('position').needsUpdate = true

		// NOW DRAW LINES
		this.lines.geometry = this.geometry.clone()
		let V = this.geometry.attributes.position.array
		let vertices = [
			V[0],
			V[1],
			V[2],
			V[3],
			V[4],
			V[5], //near
			V[6],
			V[7],
			V[8],
			V[9],
			V[10],
			V[11],
			V[0],
			V[1],
			V[2],
			V[9],
			V[10],
			V[11],
			V[3],
			V[4],
			V[5],
			V[6],
			V[7],
			V[8],
			V[12],
			V[13],
			V[14],
			V[15],
			V[16],
			V[17], //far
			V[18],
			V[19],
			V[20],
			V[21],
			V[22],
			V[23],
			V[12],
			V[13],
			V[14],
			V[18],
			V[19],
			V[20],
			V[15],
			V[16],
			V[17],
			V[21],
			V[22],
			V[23],
			V[0],
			V[1],
			V[2],
			V[12],
			V[13],
			V[14], //topLeft
			V[3],
			V[4],
			V[5],
			V[15],
			V[16],
			V[17], //topRight
			V[6],
			V[7],
			V[8],
			V[21],
			V[22],
			V[23], //BottomRight
			V[9],
			V[10],
			V[11],
			V[18],
			V[19],
			V[20], //BottomLeft
		]

		let color = [
			1,
			1,
			1,
			1,
			1,
			1, // NEAR
			1,
			1,
			1,
			1,
			1,
			1,
			1,
			1,
			1,
			1,
			1,
			1,
			1,
			1,
			1,
			1,
			1,
			1,

			0,
			0,
			1,
			0,
			0,
			1, // FAR
			0,
			0,
			1,
			0,
			0,
			1,
			0,
			0,
			1,
			0,
			0,
			1,
			0,
			0,
			1,
			0,
			0,
			1,

			1,
			1,
			1,
			0,
			0,
			1, // top/bottom/left/right
			1,
			1,
			1,
			0,
			0,
			1,
			1,
			1,
			1,
			0,
			0,
			1,
			1,
			1,
			1,
			0,
			0,
			1,
		]

		this.lines.geometry.setAttribute(
			'position',
			new THREE.Float32BufferAttribute(vertices, 3)
		)
		this.lines.geometry.setAttribute(
			'color',
			new THREE.Float32BufferAttribute(color, 3)
		)
		console.log(this.lines.geometry)
		this.lines.geometry.index = null

		//	this.geometry.clone()

		this.select()
		/*
		//@ts-ignore
		this.three.nodes2.map((node) => {
			node.JSXNode.div.style.opacity = '0.1'
		})

		console.log('hel.lo', this.three.selectedEntities )
		MUST GET ACCESSORS TO NODES2 FILTER BUY ENTITY ID
		MUST GET ACCESSORS TO NODES2 FILTER BUY ENTITY ID
		MUST GET ACCESSORS TO NODES2 FILTER BUY ENTITY ID
		MUST GET ACCESSORS TO NODES2 FILTER BUY ENTITY ID

		//@ts-ignore
		this.three.nodes2.forEach((nodes)=>{
		node.styles.activate('highlight')
		})
		*/

		this.MouseMenu.update(
			this,
			vertices[0] + (vertices[3] - vertices[0]) / 2,
			vertices[1] + (vertices[4] - vertices[1]) / 2,
			vertices[2] + (vertices[5] - vertices[2]) / 2
		)
	}

	setPoint(point, camera, x, y, z) {
		console.log(point, x, y, z, this.pointMap)

		const _vector = new THREE.Vector3(x, y, z).unproject(camera)

		const points = this.pointMap[point]

		if (points !== undefined) {
			const position = this.geometry.getAttribute('position')
			for (let i = 0, l = points.length; i < l; i++) {
				position.setXYZ(points[i], _vector.x, _vector.y, _vector.z)
			}
		}
	}

	select() {
		let intersected = []

		var intersects
		var raycaster = new THREE.Raycaster()

		//@ts-ignore
		this.three.nodes2.map((node) => {
			let n = node
			let pos = new THREE.Vector3(0, 0, 0)
			n.group.getWorldPosition(pos)
			raycaster.set(pos, new THREE.Vector3(0, 0, 1))

			intersects = raycaster.intersectObjects([this], false)

			// check if entity is INSIDE the shape
			// if collision is ODD
			// point is NECESSERLY INSIDE the shape
			if (intersects.length % 2) intersected.push(node.data.pid)
		})
		console.log('INTERSECTED', intersected)

		//this.three.nodes.selecteds(intersected)

		//@ts-ignore
		this.three.selectedEntities = new Avial.Values.V_Array(
			intersected.map((i) => new Avial.Values.V_Entity(i))
		)
		this.three.triggerReactLifeCycle()
	}

	dispose() {
		//	this.three.nodes.selecteds([])
		//@ts-ignore
		this.three.selectedEntities = new Avial.Values.V_Array([])
		this.three.triggerReactLifeCycle()

		this.MouseMenu.destruct()

		this.three.scene.remove(this)
		this.geometry.dispose()
		//@ts-ignore
		this.material.dispose()
	}
}

export { MouseSelect }

import EntityCreator from "./EntityCreator";
import EntityViewer  from "./EntityViewer";
import Outliner from "./Outliner";
import Viewport from "./viewport/Viewport";
import LaplacianInstrument from "./laplacian/index";
import Logger from "./Logger";
import WindowManager from "./WindowManager/WindowManager";
import Maps from "./map/Maps";
import EntityLinkerDebug from "./EntityLinkerDebug";
import Printer from "./Printer";
import Keyboard from "./Keyboard";
import Hexdump from "./Hexdump";
import InstrumentSelector from "./InstrumentSelector";
import Terminal from "./terminal/Terminal";
import Settings from "./Settings/Settings";
import Chart from "./Chart/Chart";

import Mount from "./Mount";
import Package from "./Package";

import ValueBuilder from "./ValueBuilder";

export default {
	ValueBuilder: {
		component: ValueBuilder,
		name: "ValueBuilder",
		icon: "BiCodeCurly",
	},
	Package: {
		component: Package,
		name: "Package",
		icon: "MdOutlineSettings",
	},
	Mount: {
		component: Mount,
		name: "Mount",
		icon: "MdOutlineSettings",
	},
	Settings: {
		component: Settings,
		name: "Settings",
		icon: "MdOutlineSettings",
	},
	Terminal: {
		component: Terminal,
		name: "Terminal",
		icon: "BsTerminal",
	},
	InstrumentSelector: {
		component:InstrumentSelector ,
		name: "InstrumentSelector",
		icon: "MdSwitchLeft",
	},

	Hexdump: {
		component: Hexdump,
		name: "Hexdump",
		icon: "BsFileEarmarkBinary",
	},
	Keyboard: {
		component: Keyboard,
		name: "Keyboard",
		icon: "MdOutlineKeyboardAlt",
	},
	Printer: {
		component: Printer,
		name: "Printer",
		icon: "BsFileEarmarkCode",
	},
	EntityLinkerDebug: {
		component: EntityLinkerDebug ,
		name: "LinkerDebug",
		icon: "BsTerminal",
	},
	logger: {
		component: Logger,
		name: "logger",
		icon: "BsTerminal",
	},

	windowManager: {
		component: WindowManager,
		name: "windowManager",
		icon: "AiOutlineNodeIndex",
	},
	outliner: {
		component: Outliner,
		name: "outliner",
		icon: "BsFillSignpostSplitFill",
	},
	viewport: {
		component: Viewport,
		name: "viewport",
		icon: "BsBox",
	},

	Laplacian: {
		component: LaplacianInstrument,
		name: "Laplacian",
		icon: "MdGridOn",
	},
	EntityViewer: {
		component: EntityViewer,
		name: "EntityViewer",
		icon: "BsFileEarmarkCodeFill",
	},
	createEntity: {
		component: EntityCreator,
		name: "createEntity",
		icon: "RiFileEditFill",
	},
	map: {
		component: Maps,
		name: "map",
		icon: "BiWorld",
	},
	Chart: {
		component: Chart,
		name: "Chart",
		icon: "AiOutlineBarChart",
	}
};

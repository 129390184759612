import { FunctionComponent, useState, useEffect } from "react";
import useKeyboard from "../../hook/keyboard";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AppState } from "../../store/types";
//import { windows, categories } from "./Windows";
import { Layout } from "../../components/layout/DefaultLayout";
import { I_WmOptions, Wm } from "@ledr/instruments";
import { panelChangeAction } from "../../store/panels/actions";


import  InstrumentsLinkerOverseer from "../../components/instrumentLinker/instrumentLinkerOverseer"
import { InstrumentsLinker } from "../../components/instrumentLinker/InstrumentLinker";

import windows from "../../instruments";
import "./Browser.scss";


const Browser: FunctionComponent = () => {
	const dispatch = useDispatch();

	const location = useLocation();
	const current = location.pathname.split("/")[1];

	const panel = useSelector((state: AppState) =>
		state.panels.panels.find((p) => p.id === current)
	);

	const categories = useSelector((state: AppState) =>
		panel?.categories.map((e) =>
			state.panels.categories.find((c) => c.id === e)
		)
	);

	const [debug, setDebug] = useState(false);
	const [showNewWindow, setShowNewWindow] = useState(false);

	useKeyboard([
		{
			action: "showDebug",
			cb: setDebug
		},
		{
			action: "showNewWindow",
			cb: setShowNewWindow
		}
	]);


	if (!panel) return <>LOADING PANEL</>;
	return (
		<Layout>

				<InstrumentsLinkerOverseer
					links={panel.links}
					onChange={(links) => {
						dispatch(
						panelChangeAction({
							...panel,
							links: links,
					})); }}
				>

				{/*
			PUT TOOLBELT HERE 	
			PUT TOOLBELT HERE 	
			PUT TOOLBELT HERE 	
					*/}
					<div style={{ height: "100%", padding: "4px" }}>

						<Wm
							tilingWindows={panel.twmArbo}
							floatingWindows={panel.fwmWindows}
							categories={categories}
							windows={windows}
							options={{
								...panel?.options,
								showNewWindow: showNewWindow,
									debug: debug ? debug : panel?.options.debug,
							}}
							onChange={(twmArbo, floatingWindows) => {
								dispatch(
								panelChangeAction({
									...panel,
									twmArbo,
									fwmWindows: floatingWindows,
							})
							);
							}}
							WrappingWindow={ InstrumentsLinker }
						/>
					</div>

				</InstrumentsLinkerOverseer>

		</Layout>
	);
};

export default Browser;

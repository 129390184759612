import { FunctionComponent } from 'react'
import Avial, {AvialType} from '@ledr/ts-client'
import { ValueInput, Icon } from '@ledr/instruments'

interface FactorsProps {
	factors: AvialType.Factors;
	onChange: (newFactors: AvialType.Factors) => void;
}

const Factors: FunctionComponent<FactorsProps> = (props) => {

	const changeFactor = (oldFactor, newFactor) => {
		props.onChange(props.factors.map(f => f === oldFactor ? newFactor : f))
	}

	const addFactor = () => {
		props.onChange(
			[...props.factors, new Avial.Model.Factor("", new Avial.Values.V_Null(""))]
		)
	}
	const deleteFactor= (factorToDelete) => {
		props.onChange(props.factors.filter(f => f !== factorToDelete))
	}

	return (
		<>
			{props.factors.map((factor,i)=>
			<div style={{display: "grid", gridTemplateColumns:"repeat(2,auto) min-content"}}>
				<div className={"cell"}>
					<input
						style={{height:"22px", width:"100%"}}
						type={"text"}
						value={factor.Key}
						onChange={ (evt) =>
							changeFactor(factor, new Avial.Model.Factor(evt.target.value, factor.Value))
						}
						placeholder={`Key`}
					/>
				</div>
				<div className={"cell"}>
					<ValueInput
						value={factor.Value}
						onChange={ (newValue) =>
							changeFactor(factor, new Avial.Model.Factor(factor.Key, newValue))
						}
					/>
				</div>
				<button
					style={{
						background: "rgba(255,0,0,0.2)",
						color: "rgba(255,0,0,1)",
						padding: "1px",
						margin: "1px",
						height: "100%"
					}}
					onClick={()=>deleteFactor(factor)}
				>
					<Icon name={"MdOutlineCancel"} size={20} />
				</button>
			</div>
			)
			}

				<button
					style={{
						background: "rgba(0,255,0,0.2)",
						color: "rgba(0,255,0,1)",
						padding: "1px",
						width: "100%"
					}}
					onClick={()=>addFactor()}
				>

					<Icon name={"MdAddCircleOutline"} size={20}/>

				</button>

		</>
	)
}

interface FacetsProps {
	facets: AvialType.Facets;
	onChange: (facets: AvialType.Facets) => void;
}

const Facets: FunctionComponent<FacetsProps> = (props) => {

	const changeFacet = (oldFacet, newFacet) => {
		props.onChange(
			new Avial.Model.Facets(
				
				props.facets.map( f => f === oldFacet ? newFacet : f),
				
			)
		)
	}
	const addFacet = () => {
		props.onChange(
			new Avial.Model.Facets(
				[
					...props.facets,
					new Avial.Model.Facet( "", new Avial.Values.V_Null(""), [])
				]
			)
		)
	}

	const deleteFacet = (facet) => {
		props.onChange(
			new Avial.Model.Facets(
				props.facets.filter(f => f !== facet)
			)
		)
	}

	return (
		<div style={{display: "grid", gridTemplateColumns:"repeat(3, auto) min-content"}}>
			<div className={"cellHead"}>Name</div>
			<div className={"cellHead"}>Value</div>
			<div className={"cellHead"}>Factors</div>
			<div></div>
			{ props.facets?.map((facet, i) =>
			<>
				<div className={"cell"}>
					<input
						style={{height:"22px", width:"100%"}}
						type={"text"}
						value={facet.Name}
						onChange={(evt) => 
							changeFacet(facet, new Avial.Model.Facet(
									evt.target.value,
									facet.Value,
									facet.Factors
								))
						}
					/>
				</div>

				<div className={"cell"}>
					<ValueInput
						value={facet.Value}
						onChange={newValue => 
							changeFacet(facet, new Avial.Model.Facet(
									facet.Name,
									newValue,
									facet.Factors
								))
						}
					/>
				</div>

				<div className={"cell"}>
					<Factors factors={facet.Factors}
						onChange={newFactors => 
							changeFacet(facet, new Avial.Model.Facet(
									facet.Name,
									facet.Value,
									newFactors
								))
						}
					/>
				</div>

				<button
					style={{
						background: "rgba(255,0,0,0.2)",
							color: "rgba(255,0,0,1)",
							padding: "1px",
							margin: "1px",
							height: "100%"
					}}
					onClick={()=>deleteFacet(facet)}
				>
					<Icon name={"MdOutlineCancel"} size={20} />
				</button>

			</>	
			)
			}

			<button
				style={{
					gridColumn: "1/4",
						background: "rgba(0,255,0,0.2)",
						color: "rgba(0,255,0,1)",
						padding: "1px",
						width: "100%"
				}}
				onClick={(evt)=> {
					evt.preventDefault();
					evt.stopPropagation();
					addFacet();
				}
				}>
				<Icon name={"MdAddCircleOutline"} size={20}/>
			</button>
		</div>
	)
}

export default Facets;

import { useState, useEffect, useContext } from 'react';
import ApiContext from "../context/api";
import {AvialType} from "@ledr/ts-client";

import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../store/types";
import {I_EntityStore} from '../store/entities/reducer';
import {
	loadEntityDataSuccessAction,

	loadEntityContentsAction,
	loadEntityContentsSuccessAction,
	loadEntityContentsErrorAction,

	loadEntityMetadataAction,
	loadEntityMetadataSuccessAction,
	loadEntityMetadataErrorAction,

} from '../store/entities/actions';

// IN CASE OF
// MULTIPLES COMPONENTS USING SIMULTANEE THIS useEntity
// REDUX ASCYNC DISPATCH TO SET ENTITY IS LOADING IS TO SLOW
// SO EACH COMPONENTS REQUEST FETCHING

let WARNING_SINGLETON = []; 

// // // // // // // // // // // // // // // // // // // // //

export default function useEntity(entity: AvialType.Values.V_Entity): I_EntityStore {
	const dispatch = useDispatch();
	const api = useContext(ApiContext);

	const entityStore = useSelector((state: AppState) => 
		(entity)
			? state.entities[entity.toString()]
			: undefined,
	);


	const endpointStatus = useSelector((state: AppState) => state.endpoints[0].status);
	const keychain = useSelector((state: AppState) => state.user.keychain.current);

	useEffect(()=>{
		if (!api.session || !endpointStatus)
			return;

		if(!entityStore && !WARNING_SINGLETON.includes(entity.toString()))
		{
			WARNING_SINGLETON.push(entity.toString())
			dispatch(loadEntityContentsAction(entity))
			dispatch(loadEntityMetadataAction(entity))

			api.get(entity)
				.then((e: AvialType.AvialModel.EntityData) => {
					dispatch(loadEntityMetadataSuccessAction(entity, e.metadata))
					//@ts-ignore
					dispatch(loadEntityContentsSuccessAction(entity, e.contents))
				})
				.catch((e) => {

					dispatch(loadEntityMetadataErrorAction(entity, e.metadata))
					dispatch(loadEntityContentsErrorAction(entity, e.contents))

				})
			  .finally(() => {
					let idx = WARNING_SINGLETON.findIndex(e => e === entity.toString())
					WARNING_SINGLETON = [
						...WARNING_SINGLETON.slice(0,idx),
						...WARNING_SINGLETON.slice(idx + 1),
					]
				})
		}

	},[endpointStatus, api.session, entityStore, keychain])

	return entityStore ? entityStore : {
			isLoadingMetadata: true,
			isLoadingContents: true,
	};
}


export function useEntities(entities: AvialType.Values.V_Entity[]): I_EntityStore[] {
	const dispatch = useDispatch();
	const api = useContext(ApiContext);

	const entitiesStore = useSelector((state: AppState) => 
		entities.map(entity => (entity)
			? state.entities[entity.toString()]
			: undefined,
		)
	);

	const endpointStatus = useSelector((state: AppState) => state.endpoints[0].status);
	const keychain = useSelector((state: AppState) => state.user.keychain.current);

	useEffect(()=>{
		if (!api.session || !endpointStatus)
			return;

		entities.map(entity=> {

			if(!entitiesStore.find(e =>
				e?.metadata?.Fields?.Entity === entity.toString() 
			)
				&& !WARNING_SINGLETON.includes(entity.toString()))
			{
				console.log("RUN FETCH FOR", entity.toString() )
				console.log(WARNING_SINGLETON.toString(), entitiesStore.toString())
				console.log( WARNING_SINGLETON.includes(entity.toString()));
				console.log("---------------------");

				WARNING_SINGLETON.push(entity.toString())

				dispatch(loadEntityContentsAction(entity))
				dispatch(loadEntityMetadataAction(entity))

				api.get(entity)
					.then((e: AvialType.AvialModel.EntityData) => {
						dispatch(loadEntityDataSuccessAction(
							entity,
							e.metadata,
							//@ts-ignore
							e.contents
						))
						/*
						dispatch(loadEntityMetadataSuccessAction(entity, e.metadata))
						dispatch(loadEntityContentsSuccessAction(entity, e.contents))
						*/
					})
					.catch((e) => {
						dispatch(loadEntityMetadataErrorAction(entity, e.metadata))
						dispatch(loadEntityContentsErrorAction(entity, e.contents))
					})
					.finally(() => {
						let idx = WARNING_SINGLETON.findIndex(e => e === entity.toString())
						WARNING_SINGLETON = [
							...WARNING_SINGLETON.slice(0,idx),
							...WARNING_SINGLETON.slice(idx + 1),
						]
					})
			}
		})

	},[endpointStatus, api.session, entitiesStore, keychain])

	return entitiesStore.map(entity => 
		entity ? entity 
			: {
				isLoadingMetadata: true,
				isLoadingContents: true,
		}
	)
}

//@ts-nocheck
import { FunctionComponent, useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import Avial, {AvialType} from "@ledr/ts-client";
import {EntityInput} from "@ledr/instruments";
import {
  Print,
  defaultDrawer,
  Drawer,
  debugDrawer,
  avesterraDrawer,
	DebugWrapper,
	DisplayTagWrapper,
	//EditWrapper
} from "@ledr/instruments";

import ApiContext from "../context/api";
import { AppState } from "../store/types";

import "./connectedComponents.scss";

interface EntityLoaderProps {
  entityToLoad: AvialType.Values.V_Entity;
}


function tagEditDrawer(onChange):Drawer {
	return {
	drawerName: "Tag Edit Drawer",
	pencils   : {
		Null: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Null,
			component: () => <></>
		},
		Avesterra: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Avesterra,
			component: () => <></>
		},
		Boolean: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Boolean,
			component: () => <></>
		},
		Character: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Character,
			component: () => <></>
		},
		String: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_String,
			component: () => <></>
		},
		Text: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Text,
			component: () => <></>
		},
		Integer: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Integer,
			component: () => <></>
		},
		Float: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Float,
			component: () => <></>
		},
		Entity: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Entity,
			component: (props) => <EntityInput value={props.value} onChange={onChange}/>
		},
		Time: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Time,
			component: () => <></>
		},
		Web: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Web,
			component: () => <></>
		},
		Interchange: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Interchange,
			component: () => <></>
		},

		Data: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Data,
			component: () => <></>
		},
		Exception: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Exception,
			component: () => <></>
		},
		Operator: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Operator,
			component: () => <></>
		},
		Function: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Function,
			component: () => <></>
		},
		/*
		Measurement: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Measurement,
			component: (props) => (
				<>
					{props.value.value.float}&nbsp;
					{props.value.value.prefix}&nbsp;
					[{props.value.value.unit}]&nbsp;
					{props.value.value.confidence}%&nbsp;
					±{props.value.value.uncertainty}
				</>
			),
		},
		Locutor: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Locutor,
			component: (props) => (
				<>
					{props.selectDrawer({
						name      : "V_Locutor",
						value     : props.value.value,
						mainDrawer: defaultDrawer,
						drawer    : defaultDrawer,
					})}
				</>
			),
		},
		Authorization: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Authorization,
			component: (props) => <span className={"number"}>{props.value.toString()}</span>,
		},
		Duration: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Duration,
			component: (props) => <span className={"number"}>{props.value.toString()}</span>,
		},
		Aggregate: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Aggregate,
			component: (props) => (
				<>
					{props.selectDrawer({
						name      : "V_Aggregate",
						value     : props.value.value,
						mainDrawer: defaultDrawer,
						drawer    : defaultDrawer,
					})}
				</>
			),
		},
		Array: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Array,
			component: (props) => (
				<V_Array {...props}/>
			),
		},
		Variable: {
			filter   : (element) => element.value?.constructor === Avial.Values.V_Variable,
			component: (props) => (

				<>
					[{props.value.name}]: {"{"}
					{props.selectDrawer({
						name  : props.value.name,
						value : props.value.value[props.value.name],
						drawer: props.mainDrawer,
					})}
					{"}"}
				</>
			),
		},
		UnknowTag: {
			component: (props) =>
			{
				return (
					<div
						style={{
							display        : "inline",
							backgroundColor: "rgba(255, 0, 0, 0.5)",
						}}
					>
            TODO VALUE TYPE :{props.value.type}
					</div>
				);
			},
		},
 */
	}
}
}

interface EditWrapperProps
{
	value:any;
	isEdit:boolean;
	selectedEntity:string;
}
const EditWrapper:FunctionComponent<EditWrapperProps> = (props) =>
{
	const [isPopupShow, setIsPopupShow] = useState(false);
	const [value, setValue] = useState<AvialType.V_Value>();

	let currentPencil = [...props.currentPencil];
	currentPencil.splice(-1);

	let newPath = [...props.path];
	newPath.pop();

	//@ts-expect-error
	let opts = {
		//drawer: d ? orchestraDrawer : defaultDrawer,
		//@ts-expect-error
		path         : newPath, //[...props.path, props.name],
		currentPencil: currentPencil,
	};



	if (isPopupShow)
		opts.drawer = tagEditDrawer(setValue);

	if (!props.isEdit)
	{
		return (
			<div style={{ border: "1px solid transparent" }}>
				{props.selectDrawer(opts)}
			</div>
		);
	}


	return (
		<>
			{/*
			<Popup show={isPopupShow} close={() => { setIsPopupShow(false); }}>
				<div style={{ border: "1px solid chocolate" }}>
					<h1>EDIT {Avial.Utils.Entity.toString(props.selectedEntity)}</h1>
					<h1>
						{props.path.join("/")}/{props.name}
					</h1>
					<hr style={{ margin: "5px 0px" }} />
					<b>CUR_PENCIL : </b> {currentPencil.join("/")}
					<br />
					<hr style={{ margin: "5px 0px" }} />
					{props.selectDrawer({
						drawer     : defaultDrawer,
						mainDrawer : defaultDrawer,
						maxDeepness: 2,
						deepness   : 0,
					})}
					<br />
					<textarea
						style={{ width: "700px", height: "300px" }}
						value={JSON.stringify(props.value)}
					></textarea>
				</div>
				<button>UPDATE ENTITY</button>
			</Popup>
				*/}
			<div
				className={"printerEditWrapper"}
				//@ts-expect-error
				onMouseEnter={() => {}}
				//@ts-expect-error
				onMouseLeave={() => {}}
				onClick={(e) =>
				{
					e.stopPropagation();
					e.nativeEvent.stopImmediatePropagation();
					if (e.isPropagationPrevented) return; // Exits here if event has been handled
					setIsPopupShow(true);
				}}
			>
				{props.selectDrawer(opts)}
				{isPopupShow && <button onClick={()=>alert(value)} >GO</button>}
			</div>
		</>
	);
};





export const EntityLoader: FunctionComponent<EntityLoaderProps> = (props) => {
  const api = useContext(ApiContext);

  useEffect(() => {
    let e = props.entityToLoad;
    api.get(`entity/${e.value.nid}/${e.value.hid}/${e.value.uid}`).then(
      () => {},
      () => {}
    );
  }, []);

  const entity = useSelector(
    (state: AppState) =>
      state.entities[props.entityToLoad.toString()]
  );

  return (
    <>
      {entity ? (
        <>{props.children}</>
      ) : (
        <div className={"entityLoader"}>
          <h1>Loading {props.entityToLoad.toString()}</h1>
        </div>
      )}
    </>
  );
};

export const PrintEntityMetadata = (props: any) => {
  const entity = useSelector(
    (state: AppState) =>
      state.entities[props.selectedEntity.toString()]
  );
  return (
    <Print
      name={"Metadata"}
      value={entity ? entity.metadata : "Loading Metadata"}
      maxDeepness={100}
    />
  );
};

interface EntityLinkProps {
  entity: AvialType.Values.V_Entity;
	onSelect: (entity: AvialType.Values.V_Entity) => void ;
}

const EntityLink: FunctionComponent<EntityLinkProps> = (props) => {
  return (
		<a>
      <span
				onClick={()=> props.onSelect(props.entity)}
				className={"number"}>
				{props.entity.toString()}
			</span>
		</a>
  );
};

export const PrintEntityStore = (props: any) => {
  const customDrawer: Drawer = {
    ...avesterraDrawer,
    pencils: {
      ...avesterraDrawer.pencils,
      Tag: {
        ...avesterraDrawer.pencils.Tag,
        subDrawer: {
          ...avesterraDrawer.pencils.Tag.subDrawer,
          pencils: {
            ...avesterraDrawer.pencils.Tag.subDrawer.pencils,
            Entity: {
              ...avesterraDrawer.pencils.Tag.subDrawer.pencils.Entity,
              component: (p, print) => (
                <EntityLink
									entity={p.value}
									onSelect={props.onSelect}
								/>
              ),
            },
          },
        },
      },
    },
  };


  const entity = useSelector(
    (state: AppState) => state.entities[props.selectedEntity.toString()]
  );
  const [newDRAWER, setNewDRAWER] = useState<Drawer>(customDrawer);
  const [maxDeepness, setMaxDeepness] = useState(4);

  const [debugInfo, setDebugInfo] = useState({
    path: "",
    deepness: "",
    currentPencil: "",
    nextPencil: "",
    value: "",
  });

  useEffect(() => {
		let buildDrawer = (props.options.isDisplayRaw)
			?	defaultDrawer
			: customDrawer;

		// PROTECT THIS FROM REBUILD (then redraw)
		if (!props.options.isDisplayRaw) {
			buildDrawer = customDrawer;
			if (props.options.isValueTypeDisplayed) {
				buildDrawer = {
					...customDrawer,
					pencils: {
						...customDrawer.pencils,
						Tag: {
							...customDrawer["pencils"]["Tag"],
							component:(props)=> {
								console.log(props)
							return(	<>he{props.selectDrawer(props.value)}HEH</>)// DisplayTagWrapper,
							}
						},
					},
				};
			}
if (props.options.isEdit)
{
			buildDrawer = {
				drawerName: "Edit Wrapper",
				pencils: {
					Edit: {
						subDrawer: buildDrawer,
						component: (e) => (
							<EditWrapper
								selectedEntity={props.selectedEntity}
								isEdit={props.options.isEdit}
								{...e}
							/>
						),
					},
				},
			};
}

		}

		if (props.options.isDebugPrinter) {
			buildDrawer = {
				drawerName: "Debug Wrapper",
				pencils: {
					DebugWrapper: {
						subDrawer: buildDrawer,
						component: (props) => (
							<DebugWrapper
								{...props}
								isDebugPrinter={true}
								hover={(debugInfo: any) => setDebugInfo(debugInfo)}
							/>
						),
					},
				},
			};
		}
		setNewDRAWER(buildDrawer);
  }, [props.options,
			props.onSelect
	]);

  return (
    <div>
      {props.options.isDisplayRaw && (
        <input
          type="range"
          min="1"
          max="20"
          value={maxDeepness.toString()}
          onChange={(e) => setMaxDeepness(parseInt(e.target.value))}
        />
      )}

      {!entity ? (
        "LOADING"
      ) : !entity.contents ? (
        <h1 style={{ textAlign: "center", color: "grey" }}>
          {entity?.metadata?.Fields?.Name}
          <br />
          NO DATA
        </h1>
      ) : (
        <Print
          name={"store"}
          value={entity ? entity.contents : "loading"}
          maxDeepness={maxDeepness}
          drawer={newDRAWER}
        />
      )}

      {props.options.isDebugPrinter && (
        <>
          <div
            style={{
              position: "fixed",
              zIndex: 100000,
              bottom: "0px",
              left: "0px",
              backgroundColor: "rgba(32, 32, 32, 0.9)",
              border: "4px solid chocolate",
            }}
          >
            <div style={{ border: "1px solid chocolate" }}>
              <table>
                <tr>
                  <td>Path</td>
                  <td>{debugInfo?.path}</td>
                </tr>
                <tr>
                  <td>Deepness</td>
                  <td>{debugInfo?.deepness}</td>
                </tr>
                <tr>
                  <td>CurrentPencil</td>
                  <td>{debugInfo?.currentPencil}</td>
                </tr>
                <tr>
                  <td>NextPencil</td>
                  <td>{debugInfo?.nextPencil}</td>
                </tr>
              </table>
            </div>
          </div>
          <div
            style={{
              position: "fixed",
              zIndex: 100000,
              bottom: "0px",
              right: "0px",
              backgroundColor: "rgba(32, 32, 32, 0.9)",
              border: "4px solid chocolate",
            }}
          >
            <div
              style={{
                position: "sticky",
                top: "50px",
              }}
            >
              <Print
                name={"drawer"}
                value={newDRAWER}
                drawer={debugDrawer}
                maxDeepness={100}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const StoredEntity = () => {
  const entity = useSelector((state: AppState) => state.entities);
	if (
		entity?.metadata && entity?.contents
	)
		return <>loading</>;
		return <>not implemented</>;
/*
  return <StoredEntityDisplayer entities={{
		metadata: entity.metadata
		contents: entity.contents
		}
		} />;
 */
};

import React, { FunctionComponent } from 'react'
import Avial, {AvialType} from "@ledr/ts-client";
import ReactEcharts from "echarts-for-react";


function facetsToSeries(facets: AvialType.Facets | undefined){
	return facets?.map(facet => {
		let toMerge:any = {};
		facet.Factors.forEach(f => {
			toMerge[f.Key] = f.Value.toString();
		})
		return {
			name: facet?.Name,
			...toMerge
		}
	});
}

function tableToDataSet(periodName: string, table: AvialType.Table | undefined){
	return (
		!table
		? []
		: [
			[periodName, ...table.columns.map(c => c.name)],
			...table.map(row => [row.Key, ...row.map(cell => cell.toString())])
		]
	)
}

interface BarChartProps {
	entityData: AvialType.AvialEntity;
}

const BarChart: FunctionComponent<BarChartProps> = (props) => {


	let Fact = props.entityData?.Facts?.getByAttribute("REVENUE_ATTRIBUTE");
	return (
		<>
			{props.entityData.Facts?.map(fact =>
				{

	let DATA = {
		dataset: tableToDataSet("", fact.Table),
		series: facetsToSeries(fact.Facets)
	}
					return(
						<React.Fragment key={fact.Attribute}>
							<h2 style={{
								margin: "15px",
								marginBottom: "0px",
								color: "rgb(185, 142, 255)",
								border: "1px solid rgb(185, 142, 255)",
								borderLeft: "15px solid rgb(185, 142, 255)",
								borderRight: "15px solid rgb(185, 142, 255)",
								backgroundColor: "#0e0e0e",
								fontSize: "14px",
								padding: "5px"
								}}>
							{fact.Attribute.slice(0, -10)}
							</h2>
							<div
								style={{
									margin: "15px",
									marginTop: "0px",
									border: "1px solid rgb(185, 142, 255)",
									borderTop: "none",
									padding: "10px"
								}}
							>
						<
						//@ts-ignore
							ReactEcharts
						  opts={{renderer: 'svg'}}
							style={{
							width: "100%",
						}}
							notMerge={true}
							option={
						{
						grid: {
							left: 40,
							right: 0,
							top: 10,
							bottom: 60,
						},
							legend: {
							orient: 'horizontal',
							left: 'center',
							bottom: 0,
			        textStyle: {color: 'white'},

						},
							dataset:
						{
							source: DATA?.dataset ?? []
						},
							xAxis: { type: 'category' },
							yAxis: {},
							series: DATA.series?.length ? DATA.series : [{ type:"bar" }, { type: 'bar' }, { type: 'bar' }]
						}
						}
						/>
							</div>
						</React.Fragment>
				)}


			)}

		</>
	)
}

export default BarChart;

//@ts-nocheck
import { FunctionComponent, useState } from "react";
import Avial from "@ledr/ts-client";
import "./Error.scss";

interface ErrorProps {
	error: Avial.Error;
	children: JSX.Element;
}

const Error: FunctionComponent<ErrorProps> = (props) => {
  return !props.error
		? <>{props.children}</>
		: <div className="Error">

			<p>
				Avial Error : {props.error.code}
				<br/>
				{props.error.message}
			</p>

			</div>
};

export default Error;

import * as THREE from 'three'
import { ThreeInit } from '../init'
import { JSX2DNode } from '../JSXObject'
import Mouse from '../components/Mouse'

class MouseMenu extends THREE.Object3D {
	three: ThreeInit
	JSXCtxMenu: JSX2DNode

	constructor(three) {
		super()
		this.three = three

		this.JSXCtxMenu = new JSX2DNode(
			this.three,
			(
				// PUT JSX MOUSE COMPONENT HERE
				<div style={{
					backgroundColor:"rgba(64, 64, 64, 1)",
					border: "1px solid grey"
					}} >
					SELECTION
					<br/>
					<button
						onClick={()=>{console.log("Yo")}}
					>
					HIDE
					</button>
					<button
						onClick={()=>{console.log("Yo")}}
					>
					MOVE
					
					</button>

					<br/>
				DISPLAY [NB]	ENTITY HeRE
					<br/>
					//freez simu while selecting
				</div>
			)
		)
		this.add(this.JSXCtxMenu.group)
	}

	update(obj: THREE.Mesh, x, y, z) {
		console.log(obj)
		this.position.set(x, y + 0.1, z)
	}

	destruct(){
		this.JSXCtxMenu.destruct()
	}
}


export default MouseMenu;

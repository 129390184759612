import { FunctionComponent, useState,useContext, useEffect, useMemo, useRef } from 'react'
import { Icon, Print } from "@ledr/instruments";
import useKeyboard from '../../hook/keyboard';

import { WindowFork } from "@ledr/instruments";
import  InstrumentsLinkerContext from "../../context/instrumentsLinker";
import  InstrumentsLinkerOverseerContext from "../../context/instrumentsLinkerOverseer";
import { Drag, Drop} from "./DragAndDrop";
import {I_WinProps} from '@ledr/instruments/dist/Components/WindowManager/Win/Win';
import './InstrumentLinker.scss'

interface I_PortProps{
	winId:string;
	port:string;
	type:string;
}

const Port: FunctionComponent<I_PortProps> = (props) => {
	const InstrumentsLinkerOverseer = useContext(InstrumentsLinkerOverseerContext); 
	const myRef = useRef();

	useEffect(()=>{
		if (myRef.current)
			InstrumentsLinkerOverseer.declareRef({
				winId: props.winId,
				port: props.port,
				ref: myRef.current,
				type: ( props.type === "cb in" || props.type === "in")
				? "in"
				: "out"
				//	"out"
			});
	},[myRef?.current])


	useEffect(()=>{
		return () => InstrumentsLinkerOverseer.removeRef();
	},[])


	if (props.type === "in" || props.type === "cb in")
		return (
			<Drop onDrop={(data) => {
				InstrumentsLinkerOverseer.setNewLink( {
					inNode: { winId: props.winId, port:props.port },
				outNode: JSON.parse(data).outNode
				})
				} }>
				<div ref={myRef} className={`link ${props.type}`} >
					{props.port}
				</div>
			</Drop>
		)
	else
		return (
			<Drag value={JSON.stringify({
				outNode:{
					winId: props.winId,
					port:props.port
				}	
				}) }>
				<div ref={myRef} className={`link ${props.type}`} >
					{props.port}
				</div>
			</Drag>
		)
}


const InstrumentsLinker: FunctionComponent<I_WinProps> = (props) => {
	const InstrumentsLinkerOverseer = useContext(InstrumentsLinkerOverseerContext); 

	const [instrumentsBreaking, setInstrumentsBreaking] = useState(false);
	const [instrumentsNextWin, setInstrumentNextWin ] = useState();

	const [portIn, setPortIn] = useState([]);
	const [portOut, setPortOut] = useState([]);


	let obj = useMemo(() => {
		let allDistantPorts = InstrumentsLinkerOverseer.allLinks
			?.filter(l => l.outNode.winId === props.win.id)
			?.map(l => 
				({
					localOut: l.outNode.port,
					...InstrumentsLinkerOverseer
					?.allPorts
					?.find(p => p.winId === l.inNode.winId)
					?.portIn
					?.find(pIn => pIn.port === l.inNode.port)
				})
			);

		let myOutPorts = allDistantPorts?.reduce((acc, cur)=>{
			if (!acc[cur.localOut])
				acc[cur.localOut] = [cur.setter]
			else
				acc[cur.localOut] = [ ...acc[cur.localOut], cur.setter]
			return acc;
		}, {});

		let out = {};

		for (let key in myOutPorts)
		{
			// FORGE IT UPPER IN OVERSEER !!!!!!!!
			// declare => register upper => dico des declarant 
			// faire les liens plus haut et les distribuer
			// ici on a les winID grato, faire des closures.
			out[key] = (data) => {
				myOutPorts[key].forEach(setter => setter?.(data))
				InstrumentsLinkerOverseer.setCached(props.win.id, key, data)

			}
			// FORGE IT UPPER IN OVERSEER !!!!!!!!
		}

		// COMPUTE AND REMAP OUT LINK INJECTION
		//console.log("HERE", out, InstrumentsLinkerOverseer.links[props.win.id] )

		return {
			declareInOut: (declared) => {
				setPortIn(declared.in);
				setPortOut(declared.out);
				InstrumentsLinkerOverseer.setNewPorts(
					{
						winId: props.win.id,
						portIn: declared.in,
						portOut: declared.out,
					}
				)
			},
			unDeclareInOut: () => {
				InstrumentsLinkerOverseer.unsetPorts(props.win.id)
				setPortIn([]);
				setPortOut([]);

			},
			out: out,
			refreshDeclareIn: (portInName, cb)=> {
				let id = portIn.findIndex(p => p.port === portInName)
				if (portIn[id]?.setter && portIn[id].setter !== cb)
				{
					let newPortIn = [...portIn];
					newPortIn[id] = 
						{
							...newPortIn[id],
							setter: cb
						}
					setPortIn(newPortIn);
					InstrumentsLinkerOverseer.setNewPorts(
						{
							winId: props.win.id,
							portIn: newPortIn,
							portOut: portOut,
						}
					)
				}
				
			}

		}
	}, [
		InstrumentsLinkerOverseer.allLinks,
		InstrumentsLinkerOverseer.allPorts,
		InstrumentsLinkerOverseer.links,
	]);

	function changeWin(doesChange){
		setInstrumentsBreaking(false);
		if (doesChange)
		{
			// BREAK LINKS
			props.wmEvents.changeInstrumentWindow(instrumentsNextWin);
			// BREAK LINKS
		}
	}
	let remapedProps = {
		...props,
		wmEvents:{
			...props.wmEvents,
			changeInstrumentWindow: (data) => {

				if (Object.keys(obj.out).length === 0) {
					props.wmEvents.changeInstrumentWindow(data);
					return
				}
				setInstrumentsBreaking(true);
				setInstrumentNextWin(data);
			}
		}
	}

//console.log(obj)

	return (
		<InstrumentsLinkerContext.Provider value={obj}>
			<div style={{
				height: "100%",
					width: "100%",
				}}>

				<div
					className={ InstrumentsLinkerOverseer.show
						? "instrumentWrapperOn"
						: "instrumentWrapperOff"
					}>
					<WindowFork {...remapedProps}/>
				</div>

				{ InstrumentsLinkerOverseer.show &&
				<div className={"portContainer"}>
					<div
						style={{
							margin: "0px auto",
								display: "flex",
								justifyContent:"space-around",
								flexDirection: "column",
								alignItems: "center"
						}}
					>

						{
							instrumentsBreaking && <div>
							<h1>WARNING YOU GONNA BREAK LINKS</h1>
							<button onClick={()=>changeWin(true)}>
								go
							</button>
							<button onClick={()=>{
								setInstrumentsBreaking(false);
								}}>
								Cancel
							</button>
						</div>
						}



						<div style={{
							color: "white",
								boxShadow: "0px 0px 20px rgba(0,0,0,1)",
								backgroundColor: "rgba(32,32,32,0.7)",
								borderRadius:"10px",
								padding: "15px",
								zIndex: 4000,
							}}
						>
							<Icon
								name={props?.windows[props?.win?.component]?.icon}
								size={35}
							/>
							<br/>
							<div style={{
								fontSize:"20px",
								fontWeight:"bold",
								marginBottom:"20px",
								marginTop:"5px"
							}}>
								{ props.win.component }
							</div>

							<div>
								{
									portIn.map((e) => 
									(e.type !== "cb")
										? <></>
										: <Port
											winId={ props.win.id }
											port={ e.port	}
											type={"cb in"}
										/>
									)}
							</div>


							{
								portIn.length !== 0 &&
									<div className={`portBox in`} >
										<h1>IN</h1>
										{
											portIn.map((e) => 
											(e.type === "cb")
												? <></>
												: <Port
													winId={ props.win.id }
													port={ e.port }
													type={"in"}
												/>
											)
										}
									</div>
							}

							{
								portOut.length !== 0 && 
									<div className={`portBox out`} >
										<h1>OUT</h1>
										{
											portOut.map((e,i) => (e.type === "cb")
												? <></>
												:
												<Port
													winId={ props.win.id }
													port={ e.port}
													type={ "out" }
												/>
											)
										}
									</div>
							}
							{
								portOut.map((e,i) => (e.type !== "cb")
									? <></>
									: <Port
										winId={ props.win.id }
										port={ e.port}
										type={"cb out"}
									/>
								)
							}

							<div style={{
								marginTop: "10px",
								fontSize: "14px",
									color: "grey"
								}}>
								WIN ID: "{ props.win.id }"
							</div>

						</div>
					</div>

				</div>

				}
			</div>
		</InstrumentsLinkerContext.Provider>
	)
}
export {InstrumentsLinker};

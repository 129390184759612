import {
	useState, useEffect, useContext,
} from "react";

import {WmContext} from "@ledr/instruments"

import { AppState } from "../store/types";
import { useDispatch, useSelector } from "react-redux";

import keyboardContext from "../context/keyboard";

export const useKeyboardMain = () =>
{
	let closure = [];
	const [pressed, setPressed] = useState(closure);

	function keyDown(evt)
	{
		if (evt.target.type === "textarea" || evt.target.type === "text") return;
		if (!closure.some((e) => e === evt.key))
		{
			closure = [...closure, evt.key];
			setPressed(closure);
		}
	}

	function keyUp(evt)
	{
		if (closure.some((e) => e === evt.key))
		{
			closure = closure.filter((e) => evt.key !== e);
			setPressed([...closure]);
		}
	}

	useEffect(() =>
	{
		document.addEventListener("keydown", keyDown, false);
		document.addEventListener("keyup", keyUp, false);
		return () =>
		{
			document.removeEventListener("keydown", keyDown, false);
			document.addEventListener("keyup", keyUp, false);
		};
	}, []);
	return pressed;
};

export default (actions) =>
{
	const win = useContext(WmContext)
	//console.log(win)
	// COULD KEEP ACTION'S STATES IN A STATE HOOK BUILDED AT MOUNTING
	// ACCORDING ACTION.TYPE SET OR NOT KEYUP AS TRIGGERER AND KEYDOWN AS ONLY SETTER (WITH STATE HOOK AS VALUE)

	const keyboardState = useContext(keyboardContext);
	const userBinds = useSelector((state:AppState) => state.devices.keyboard);

	const [currentStatus, setCurrentStatus] = useState(actions.map(e => false));

	function foundAction(keyboardState)
	{
		const ub = userBinds.filter((userBind) => keyboardState.includes(userBind.key));
		return actions.find((action) =>
			action.action === ub[0]?.action // WARNING TRIGGER ONLY FIRST FOUNDED ACTION
		);
	}

	useEffect(() =>
	{
		//console.log("REFRESH LOOP", win, actions)
		const foundedAction = foundAction(keyboardState);

		if (!foundedAction)
		{
			if (currentStatus.find((e) => e === true))
			{
				setCurrentStatus( actions.map(e => false));
				actions.map(a=> a?.cb(false))
			}
			return;
		}

		//console.log("USER KEYBOARD HAS REFRESH", keyboardState, userBinds, actions)
		//console.log("FOUNDED", foundedAction)

		let id = actions.findIndex(action => action === foundedAction);

		const ub = userBinds.filter((userBind) => keyboardState.includes(userBind.key));

		if (currentStatus[id] === false)// && ub[0].eventType === "onPress")
		{
			const newCurrentStatus = [ ...currentStatus ];
			newCurrentStatus[id] = !newCurrentStatus[id];
			setCurrentStatus(newCurrentStatus)
			foundedAction?.cb(newCurrentStatus[id]);
		}


	}, [userBinds, keyboardState, actions]);

	//return keyboardState;
};

import { FunctionComponent, useState, useEffect, useMemo, useContext } from "react";
import  InstrumentsLinkerContext from "../../context/instrumentsLinker";
import {WindowChild, Hexdump, Print} from "@ledr/instruments"; 
import Avial, {AvialType} from "@ledr/ts-client";
import Laplacian from "../../components/laplacian"
import Matrix from "./matrix"
import cuthillMckee from "./cuthillMckee"
interface AvuProps {}

function countTRBL(matrice){
	const cData=[];
	matrice.forEach((line, srcId) => {
		cData[srcId] = { left : 0, right: 0, top: 0, bottom: 0, }
		for (let i = 0, j = matrice.length; i < j; i ++){
			if (i < srcId)
				cData[srcId].top += matrice[i][srcId];
			if (i > srcId)
				cData[srcId].bottom += matrice[i][srcId];
			if (i < srcId)
				cData[srcId].left += line[i];
			if (i > srcId)
				cData[srcId].right  += line[i];
		} 
	}
	)
	return cData;
}


function genMatrice(classes, links){
	let m = new Matrix(classes.length, classes.length);

	links.forEach((l,i) =>{
		const idClassSrc = classes.findIndex(c => c === l.srcNode?.class)
		const idClassDst = classes.findIndex(c => c === l.dstNode?.class)
		if (idClassSrc !== -1 && idClassDst !== -1)
		{
			m.m[idClassSrc][idClassDst]++;
			m.m[idClassDst][idClassSrc]++;
		}
	})

	return m;
}



const LaplacianInstrument: FunctionComponent<AvuProps> = (props) => {
	const MyContext = useContext(InstrumentsLinkerContext); 
	const [graph, setGraph] = useState({NODES:[], links:[]});
	const [classes, setClasses] = useState([]);
	const [links, setLinks] = useState([]);

	const [matriceSorted ] = useState([]);

	useEffect(()=>{
		MyContext.declareInOut( {
			in : [ {port: "graph", setter: setGraph}, ],
			out :[  ]
		})	
		return () => { MyContext.unDeclareInOut() }
	}, [])


	useEffect(()=>{

		const classes = [];
		graph.NODES?.forEach((n)=>{
			if (!n.class )
			{
				if (!classes.find((c) => c === "UNDEFINED"))
					classes.push("UNDEFINED")
			}
			else if (!classes.find((c) => c === n.class))
				classes.push(n.class)
		})

		setClasses(classes)

		setLinks(graph.links.map(l => {
			let src = graph.NODES.find(N => N.pid === l.src)
			let dst = graph.NODES.find(N => N.pid === l.dst)
			return {
				srcNode : src,
				dstNode : dst
			}
		} 
		))
	},[graph])




	const matrice = genMatrice(classes, links)
	let matrice2 = matrice.clone();
	let newOrder = matrice2.cuthillMckee().map((i) => classes[i]);

	const tab = useMemo( () => {
		return (
		<div>
			<button>entities</button>
			<button>classes</button>
		</div>
	)
	}, [classes, links ]);

	const win = useMemo(() => (
		<>

			{/*
		<Print value={matrice} />
		<Print value={graph} />
		<Print value={classes} />
				*/}

			<Laplacian legend={classes} matrix={matrice}/>
			<hr/>
			<Laplacian legend={newOrder} matrix={matrice2}/>
		</>
	), [classes, links, matrice]);

	return <WindowChild tab={tab}>{win}</WindowChild>;
};

export default LaplacianInstrument;


import { FunctionComponent, useState, useEffect, useMemo, useContext } from "react";
import  InstrumentsLinkerContext from "../context/instrumentsLinker";

import {
	Print,
	WindowChild,
} from "@ledr/instruments";



interface StoreProps {}
const Store: FunctionComponent<StoreProps> = (props) => {
	const MyContext = useContext(InstrumentsLinkerContext); 
	const [currentValue, setCurrentValue] = useState<any>();
	const [maxDeepness, setMaxDeepness] = useState(2);

	useEffect(()=> {
		MyContext?.out?.["currentValue"]?.(currentValue)
	}, [ MyContext?.out?.["currentValue"], currentValue ])

	useEffect(()=>{
		MyContext.declareInOut( {
				in : [ {port: "currentValue", setter: setCurrentValue}, ],
				out :[ {port: "currentValue" }, ]
			})	
		return () => { MyContext.unDeclareInOut() }
	}, [])

	const tab = useMemo( () => (<>Printer Tab</>), []);
	const win = useMemo(() =>
		(
			<>
				<input type="range" step="2" min="2" max="11" value={maxDeepness} onChange={(e) => setMaxDeepness(Number(e.target.value))} />
				<Print
					name={"currentValue"}
					value={currentValue}
					maxDeepness={maxDeepness}
				/>
			</>
		), [currentValue, maxDeepness]);
	return <WindowChild tab={tab}>{win}</WindowChild>;
};

export default Store;

import React, { FunctionComponent, useState } from 'react'
import './DragAndDrop.scss'

interface DropProps{
	children: JSX.Element;
	onDrop:(data: any)=>any;
}
const Drop: FunctionComponent<DropProps> = (props) => {
	const [isDragOver, setIsDragOver] = useState(false);
	function dragOver(ev) {
		ev.preventDefault()
		setIsDragOver(true)
		console.log(ev)
	}

	function dragLeave(ev) {
		ev.preventDefault()
		console.log(ev)
		setIsDragOver(false)
	}

	function drop(ev) {
		const droppedItem = ev.dataTransfer.getData("drag-item");
		console.log("droppedEvt", droppedItem)
		if (droppedItem) {
			props.onDrop( droppedItem)
		}
		setIsDragOver(false)
	}

	return(
		<div
			onDragOver={dragOver}
			onDragLeave={dragLeave}
			onDrop={drop}
			className={isDragOver ? "drop active" : "drop"}
		>
			{props.children}
		</div>);
}



interface DragProps {
	children: JSX.Element;
	value: any;
}

const Drag: FunctionComponent<DragProps> = (props) => {

	function startDrag(evt) {
		evt.dataTransfer.setData("drag-item", props.value);
		console.log("startDrag", evt)
	}

	return(
		<div
			draggable
			onDragStart={startDrag}
			className={"drag"}
		>
				{props.children}
		</div>
	);
}

export {Drag, Drop};

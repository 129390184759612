import { FunctionComponent, useState, useEffect, useContext, useMemo } from "react";
import Avial, {AvialType} from "@ledr/ts-client";
import {Print} from "@ledr/instruments"; 
import ApiContext from "../context/api";

import { WindowChild, } from "@ledr/instruments";
import "./Mount.scss";


interface PackageProps{}

const Package: FunctionComponent<PackageProps> = (props) => {

	const [list, setList] = useState(null);

	const api = useContext(ApiContext);
	useEffect(()=>{

		api.session.invokeEntity({
			entity: {nid: 0, hid: 0, uid: 100087n},
			method: Avial.Taxonomy.Method.EXECUTE,
			authorization: new Avial.Values.V_Authorization("0e9bb065-e68b-43f6-8037-82875b425def").value,
			name: "LIST"
			/*
			attribute?: AvAttribute;
			name?: AvName;
			value?: AvValue;
			parameter?: AvParameter;
			resultant?: AvResultant;
			index?: AvIndex;
			instance?: AvInstance;
			offset?: AvOffset;
			count?: AvCount;
			aspect?: AvAspect;
			context?: AvContext;
			category?: AvCategory;
			class?: AvClass;
			event?: AvEvent;
			mode?: AvMode;
			state?: AvState;
			condition?: AvCondition;
			precedence?: AvPrecedence;
			time?: AvTime;
			timeout?: AvTimeout;
			auxiliary?: AvEntity;
			ancillary?: AvEntity;
			authority?: AvAuthorization;
			 */
		})
			.then( (res)=> {
				console.log("ICICICI", res)
				setList(JSON.parse(res.value.value))})
			.catch( (res)=> console.log("INVOKE LIST", res))
	},[]);




	const tab = useMemo( () => ( <>PACKAGE TAB</>), []);
	const win = useMemo(() =>
		<div
			className="Mount"
			style={{
				display: "grid",
				gridTemplateColumns: "auto auto auto auto"
			}}>
			<div className="head">Name</div>
			<div className="head">Version</div>
			<div className="head">Package ID</div>
			<div className="head">Action</div>
			{
				list?.map( l => (
					<>
						<div>
							{l.name}
						</div>
						<div>
							{l.version}
						</div>

						<div>
							{l.package_id}
						</div>
						<div>
							<button>Install/Remove</button>
						</div>
					</>
				)) }
		</div>
		, [list ]);
	return <WindowChild tab={tab}>{win}</WindowChild>;
};

export default Package;

//@ts-ignore
import { notif } from "@ledr/layout";
import {
  newPanelAction,
  newCategoryAction,
} from "../../store/panels/actions";

import {
  FunctionComponent,
  useCallback,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import ReactFlow, { MiniMap, Controls, Background } from "react-flow-renderer";
import {
  Node,
  Edge,
  applyEdgeChanges,
  applyNodeChanges,
  addEdge,
} from "react-flow-renderer";

import { useDispatch, useSelector } from "react-redux";
import {
  panelChangeAction,
  panelRemoveCategoryAction,
  panelDeleteAction,
  categoryChangeAction,
  categoryDeleteAction,
} from "../../store/panels/actions";
import { AppState } from "../../store/types";

import "./WindowManager.scss";

import {
  I_Category,
  T_FwmWindows,
  T_TwmArbo,
  WindowChild,
} from "@ledr/instruments";

import Avial from "@ledr/ts-client";
import SelectNode from "./SelectNode";
import PanelNode from "./PanelNode";
import { I_Panels } from "../../store/panels/reducer";
import ApiContext from "../../context/api";
import Toolbox from "../../components/hud/Toolbox";

const nodeTypes = {
  category: SelectNode,
  panel: PanelNode,
};

const updateCategories = (categories, onChangeCategory) => {
  return categories.map((e, i) => ({
    id: e.id,
    type: "category",
    data: {
      category: e,
      onChangeCategory: (newCategory) =>
        onChangeCategory(i, {
          id: e.id,
          ...newCategory,
        }),
    },
    position: { x: -400, y: i * 300 },
  }));
};

const updatePanels = (panels, categories, onChangePanel) => {
  return panels.map((p, i) => ({
    id: p.id,
    type: "panel",
    position: { x: 400, y: i * 600 },
    draggable: false,
    data: {
      panel: p,
      categories: p.categories.map((e) => categories.find((c) => c.id === e)),
      //@ts-ignore
      onChange: (panel: I_Panels) =>
        onChangePanel({
          ...p,
          ...panel,
          options: {
            ...p.options,
            ...panel.options,
          },
        }),
    },
  }));
};

interface FlowProps {}
const Flow: FunctionComponent<FlowProps> = () => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  const panels = useSelector((state: AppState) => state.panels.panels);
  const categories = useSelector(
    (state: AppState) => state.panels.categories
  );
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("SET NODES");
    setNodes([
      ...updateCategories(
        categories,
        (i: number, newCategory: I_Category[]) => {
          dispatch(categoryChangeAction(i, newCategory));
        }
      ),
      ...updatePanels(panels, categories, (panels: I_Panels) =>
        dispatch(panelChangeAction(panels))
      ),
    ]);

    setEdges(
      panels
        .map((p) =>
          p.categories.map((c) => ({
            id: `link-${c}-${p.id}`,
            source: c,
            target: p.id,
          }))
        )
        .flat(Infinity) as Edge[]
    );
  }, [panels, categories]);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => {
      console.log("onEdgesChange", changes);
      setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    [setEdges]
  );

  const onConnect = (params) => {
    let panel = panels.find((e) => e.id === params.target);

    panel = {
      ...panel,
      categories: [...panel.categories, params.source],
    };
    dispatch(panelChangeAction(panel));
    setEdges((eds) => addEdge(params, eds));
  };

  const userIdentity = Avial.Utils.Entity.fromString(
    useSelector((state: AppState) => state.user.identity)
  );

  const panelsStore = useSelector((state: AppState) => state.panels);
  const api = useContext(ApiContext);

  const onNodesDelete = (nodes) => {
    nodes.forEach((node) => {
      switch (node.type) {
        case "panel":
          dispatch(panelDeleteAction(node.id));
          break;
        case "category":
          dispatch(categoryDeleteAction(node.id));
          break;
        default:
          console.log(`Deleted unknow node type: ${node.type}`);
      }
    });
  };

  const onEdgesDelete = (edges) => {
    edges.forEach((edge) => {
      dispatch(panelRemoveCategoryAction(edge.target, edge.source));
      console.log(edge);
    });
  };

  const saveAll = () => {
    console.log("panel at save ", panelsStore);

		api.adapt()
/*
    api.panels.save(userIdentity, panelsStore).then(
      () => {
        notif.success("Panels saved");
      },
      () => {}
    );
 */
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
        }}
      >
        <ReactFlow
          nodes={nodes}
          nodeTypes={nodeTypes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodesDelete={onNodesDelete}
          onEdgesDelete={onEdgesDelete}
          // WARNING !
          // THIS WILL UNMOUNT NON VISIBLE ELEMENTS
          // SO RESET LOCAL STATE OF
          //onlyRenderVisibleElements={true}
          minZoom={0.1}
          maxZoom={2}
          fitView
        >
          {/*
			<MiniMap />
					*/}
          <Controls />
          <Background gap={16} size={0.5} color={"#333"} />
        </ReactFlow>
      </div>

      <div
        style={{
          position: "absolute",
          zIndex: 100,
          top: "0px",
        }}
      >
        <Toolbox
          tools={[
            {
              icon: "AiFillSave",
              state: false,
              onClick: saveAll,
              label: "Save all",
            },
            {
              icon: "MdDashboardCustomize",
              state: false,
              onClick: () =>
                dispatch(
                  newPanelAction(
                    `TMP_${(Math.random() * 10000).toFixed(0).toString()}`
                  )
                ),
              label: "New panel",
            },
            {
              icon: "MdOutlineCategory",
              state: false,
              onClick: () =>
                dispatch(
                  newCategoryAction(
                    `TMP_${(Math.random() * 10000).toFixed(0).toString()}`
                  )
                ),
              label: "New Category",
            },
          ]}
        />
      </div>
    </div>
  );
};

interface WindowManagerProps {}
const WindowManager: FunctionComponent<WindowManagerProps> = () => {
  const tab = useMemo(() => <>Window Manager Tab</>, []);
  const win = useMemo(() => <Flow />, []);
  return <WindowChild tab={tab}>{win}</WindowChild>;
};

export default WindowManager;

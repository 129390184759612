import React, { FunctionComponent, useState } from 'react'
import { Icon } from "@ledr/instruments";
import './Hud.scss'


interface PanelProps {
	position: "top" | "topLeft" | "topRight" | "bottomLeft" | "bottomRight";
}

const Panel: FunctionComponent<PanelProps> = (props) => {
	const [isOpen, setIsOpen] = useState(true);

	return (
		<div className={`hud ${props.position ?? "topLeft"}`}>
			{props.children}
		</div>
	)

}

interface PanelContentProps{
	title: string;
	isOpen?: boolean;
}

const PanelContent: FunctionComponent<PanelContentProps> = (props) => {
	const [isOpen, setIsOpen] = useState(props.isOpen ?? false);

	return (
		<div className={"threeVisibilityTool"}>
			<div className={"title"}
				onClick={() => setIsOpen(!isOpen)}
			> {props.title}
				<span className={"isOpen"}>
					{isOpen ? "▼" : "▶"}
				</span>
			</div>

			{isOpen &&
			<div style={{ flex: 1 }}>
				<div className={"content"}>
					{props.children}
				</div>
			</div>
			}
		</div>
	)
}

export {Panel, PanelContent};

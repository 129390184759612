import  { FunctionComponent, useState,useEffect, useMemo, useContext} from "react";
import { useDispatch, useSelector } from "react-redux";
import keyboardContext from "../context/keyboard";
import {
	setKeyBindingAction,
	changeKeyBindingAction
} from "../store/devices/actions";
import { AppState } from "../store/types";
import {
	WindowChild,
	Icon
} from "@ledr/instruments";
import "./Keyboard.scss";
import useKeyboard from "../hook/keyboard";
import jsonKeyboard from "./keyboard.json"


interface KeyBindProps{
	keyBind: any;
}

const icon = {
	Escape: "MdExitToApp",
	Shift: "BsShift",
	CapsLock: "BsCapslock",
	Backspace: "BsBackspace",
	Enter: "AiOutlineEnter",
	Tab: "MdKeyboardTab",
	Alt: "BsAlt",
	OS: "RiUbuntuLine",
	PageUp: "MdUpload",
	PageDown: "MdDownload",

	ArrowUp: "BsCaretUpFill",
	ArrowDown: "BsCaretDownFill",
	ArrowLeft: "BsCaretLeftFill",
	ArrowRight: "BsCaretRightFill",
	Delete: "MdDeleteForever",
	Home: "MdHome",
};

export const KeyBind: FunctionComponent<KeyBindProps> = (props) => {
	const dispatch = useDispatch()

	const pressed = useContext(keyboardContext);
	let [changeBind, setChangeBind] = useState(false);

	useEffect(()=>{
		if (changeBind && pressed.length === 1)
		{
			dispatch(changeKeyBindingAction(pressed[0], props.keyBind.action, props.keyBind.eventType))
			setChangeBind(false);
		}
	}, [pressed])

	return (
		<div className={"keyLine"}>
			<div className={"action"}> {props.keyBind.eventType} </div>
			<div className={"key"}>
				<div className={"keyText"}
					onClick={() =>{
						setChangeBind(true)
					}}
				>
					{!changeBind
						?  props.keyBind.key.length === 1 ? props.keyBind.key
											: icon[props.keyBind.key]
												? <Icon name={icon[props.keyBind.key]} size={21} style={{marginTop: "-1px", marginLeft: "-2px"}} />
												: props.keyBind.key === "Control"? "Ctrl" : props.keyBind.key
						: "CHANGE"
					}
				</div>

			</div>
			<div className={"action"}> {props.keyBind.action} </div>
		</div>
	)
}




interface KeyboardProps {}
const Keyboard: FunctionComponent<KeyboardProps> = (props) => {

	const pressed = useContext(keyboardContext);
	const [selectedKeyboard, setSelectedKeyboard] = useState("qwerty")
	const userKeyboard = useSelector((state: AppState) => state.devices.keyboard);


	const tab = useMemo(() => <>Keyboard Tab</>, []);
	const win = useMemo(
		() => (
			<div className={"keyboard"}>
				<h3>
					{
						Object.entries(jsonKeyboard).map(([key, value]) => 
							<div
								className={`keyboardName ${selectedKeyboard === key ? "selected" : "" }`}
								onClick={()=> {setSelectedKeyboard(key)}}>{key}</div>
						)
					}
				</h3>
				<div className={"keyboardDisplayer"}>
					{
						jsonKeyboard[selectedKeyboard].map(row => 
							<div className={"keyLine"}>
								{row.map(key=> 
								<div
									className={`key`}
									style={{width: `${40 * key.unit}px`}}
								>
									<div
										className={`keyText
										${userKeyboard.some(uk =>  (uk.key === key.key)) ? "used" : ""}
										${pressed.some(uk =>  uk === key.key) ? "pressed" : ""}
									`}
										style={
											key.key.length > 1
												?  {

													fontSize: "9px",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
										} : {
											fontSize: "15px"
										}
										}
									>

										{
											key.key.length === 1 ? key.key
											: icon[key.key]
												? <Icon name={icon[key.key]} size={21} style={{marginTop: "-1px", marginLeft: "-2px"}} />
												: key.key === "Control"? "Ctrl" : key.key
										}


									</div>
								</div>
								)}
							</div>
						)}
				</div>
				<h3>
					Saved mappings
				</h3>

				{userKeyboard.map(key => <KeyBind keyBind={key} />)}
			</div>
		),
		[ userKeyboard, pressed, selectedKeyboard ]
	);

	return <WindowChild tab={tab}>{win}</WindowChild>;
};

export default Keyboard;

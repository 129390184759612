import { FunctionComponent, useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../store/types";

import Avial from "@ledr/ts-client";
import { Print, WindowChild } from "@ledr/instruments";

interface OutlinerProps {}
const Outliner: FunctionComponent<OutlinerProps> = () => {
  const location = useLocation();
		/*
  const [selectedEntity, setSelectedEntity] = useState(
    getEntityStringFromUrl(location)
      ? Avial.Utils.Entity.fromString(getEntityStringFromUrl(location))
      : Avial.Predefined.AVESTERRA_REGISTRY
  );
  useEffect(() => {
    setSelectedEntity(
      getEntityStringFromUrl(location)
        ? Avial.Utils.Entity.fromString(getEntityStringFromUrl(location))
      	: Avial.Predefined.AVESTERRA_REGISTRY
    );
  }, [location.search]);
		*/

  const entityData = useSelector(
    (state: AppState) => state.entities["<0|0|1>"]
  );

  const tab = useMemo(() => <> Outliner </>, []);
  const win = useMemo(
    () => (
      <div style={{ padding: "10px" }}>
        {"<0|0|1>"}
        <Print
          name={"Properties"}
          value={entityData?.contents?.Properties}
          maxDeepness={0}
        />
      </div>
    ),
    []
  );
  return <WindowChild tab={tab}>{win}</WindowChild>;
};

export default Outliner;

import React, { FunctionComponent } from 'react'
import './vector.scss'

interface VectorProps {
	name?: string;
	dimentions: any;
	vector: any;
}

const Vector: FunctionComponent<VectorProps> = (props) => {

	return (
		<div className={"vector"}>
		<div className={"vectorName"}>
			{props.name}
			</div>
			{
				props.dimentions.map((d, i) =>
					<div style={{color: d.color}}>

						<div className={"number"}>

							<div className={"name"}>
								{d.name}
							</div>

							<div className={"int"}>
								{Math.trunc(props.vector[i] ?? 0)}
							</div> 

							<div className={"dot"}>
								.
							</div>

							<div className={"float"}>
								{props.vector[i]?.toFixed(7).split(".")[1]}
							</div>

						</div>

					</div>
				)
			}
		</div>
	)
}

export default Vector;

import React, { FunctionComponent, useState } from 'react'
import Avial from "@ledr/ts-client";
import { Icon} from '@ledr/instruments'
import {SubclassIcon} from "../../components/three/components/Label";

interface SearchbarProps {
	value:string;
	iconList?: object;
	onChange: (e: any) => any;
	list: any[];
	placeholder?: string;
}

const Searchbar: FunctionComponent<SearchbarProps> = (props) => {
	const [open, setOpen] = useState(false);

	const [search, setSearch] = useState("");
	const searchRegExp = new RegExp(search, 'i');

	let filtered = props.list.filter(node=> {
		return (search === ""
			|| node.name?.toLowerCase().match(search.toLowerCase())
		)
	})

	const onChange = (e) => {
		setSearch("");
		props.onChange(new Avial.Values.V_Entity(e));
	};

	return (

		<div
			className={"SearchSelect"}
			style={{ width: "300px" }}
		>

			<div style={{display: "flex"}} >


				<input
					type="text"
					style={ {
						width: "100%",
						color: props.value ? "chocolate" : ""
					} }
					value={ search }
					onChange={(e) => { setSearch(e.target.value); }}
					placeholder={"Search"}
					onFocus={()=> {
						setSearch("")
						setOpen(true)
					}}
					onBlur={()=>{
						setSearch("")
						setOpen(false)
					}}
				/>
			</div>

			{open &&
			<div className="menuWrapper"  >
				<div className="menu shadow" style={{maxHeight:"150px"}}>


						{filtered.map((node) => 
							{
								return (
									<div
										className={`entry ${node.name === props.value ? "selected": ""}`}
										style={{
											display: "grid",
												gridTemplateColumns: "min-content repeat(3, 1fr)",
												gridTemplateRows: "auto",
												whiteSpace: "nowrap"
										}}
										onMouseDown={()=> {
											setSearch("")
											onChange(node.pid)
										}}>

										{ props.iconList &&
										<div style={{ margin: "1px", marginRight: "10px" }}>
											<SubclassIcon class={node.class} size={20}/>
										</div>
										}
										<div style={{
												margin: "2px", color: node.name === props.value ? "selected": "grey" }} >

											{
												node?.name?.split(searchRegExp).map((part, index) => (
													<span key={index}>
														{index > 0 ? (
															<span style={{color:"chocolate"}}>
																{ node.name.match(searchRegExp)[0] }
															</span>
														) : null}
														{part}
													</span>
												))

											}

										</div>
										{/*
										<div style={{
												margin: "2px", color: node.name === props.value ? "selected": "grey" }} >
											{node?.class?.slice(0,-6)}
										</div>
										<div style={{
												margin: "2px", color: node.name === props.value ? "selected": "grey" }} >
											{node?.category?.slice(0,-9)}
										</div>
											*/}
									</div>
								)}
						)}

				</div>
			</div>
			}
		</div>
	)
}

export default Searchbar;

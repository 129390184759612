import { useState, useEffect, useRef, useContext } from "react";
import HudContext  from "./../../context/hud";
import { FunctionComponent } from "react";

import { ThreeInit, contextMenuEntry } from "./init";
import ThreeContext from "../../context/three";
import {Print} from "@ledr/instruments";


interface ThreeProps {
	nodes: any;
	links: any;
	contextMenu?: contextMenuEntry[];
	search?: string;
}

const Three: FunctionComponent<ThreeProps> = (props) => {
	const mountRef = useRef(null);
	const [three, setThree] = useState(null);
	const hud = useContext(HudContext)

	useEffect(() => {
		console.log("========== THREE INIT ==========");
		try {
			setThree(new ThreeInit(mountRef.current));
			//three.contextMenu = props.contextMenu;
		} catch (e) {
			console.log(e);
		}

		hud.declare([{
			name: "Camera",
			x: "right",
			y: "top",
			widget: (three)=>
				<div style={{overflow:"scroll", maxHeight: "400px"}}>
					<button onClick={() => three.simpleCamera.switchOrbit()}>Control Orbit/Flat</button>
					<br/>
					<button onClick={() => three.simpleCamera.switchPerspective()}>Camera Perspective/Orthographic</button>
					<br/>
					<button onClick={() => three.nodes.switchFlat()}>Graph switchFlat</button>
					{/*
					<Print value={three} maxDeepness={2}/>
						*/}
				</div>
			},
			/*
			{
			name: "Toto2",
			x: "center",
			y: "center",
			widget: (data)=> <Print value={data} />
			}
			 */
		]
		)
		return () => {
			console.log("========== THREE EXIT ==========");
			three?.dismount?.();
		};
	}, []);



	useEffect(() => {
		if (three)
			hud.setData("Camera", three)
	}, [props]);


	useEffect(() => {
		if (three)
			three.contextMenu = props.contextMenu;
		//hud.setData("Force2", Math.random())
	}, [three, props.contextMenu]);

	useEffect(() => {
		if (three)
			three?.search?.(props.search);
	}, [three, props.search]);

	useEffect(() => {
		if (three)
			three.nodes?.update(props.nodes, props.links)
	}, [props.nodes, props.links]);


	if (three)
		three.reactLifeCycle = (three) => { setThree({...three}) };

	return (
		<>
			<div
				style={{
					display: "flex",
						flex: "1 1 auto",
						height: "100%",
						width: "100%",
						position: "relative",
						overflow: "hidden",
				}}
				ref={mountRef}
			></div>
			{three ? (
				<ThreeContext.Provider value={three}>
					{props.children}
				</ThreeContext.Provider>
			) : (
				<div>ERROR: NO WEBGL CONTEXT</div>
			)}
		</>
	);
};

export default Three;

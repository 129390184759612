class Matrix {
	m: number[][]
	height: number
	width: number

	constructor(x: number, y: number) {
		this.height = x
		this.width = y

		this.m = new Array(x)
		for (let i = 0; i < x; i++) {
			this.m[i] = new Array(y)
			for (let j = 0; j < y; j++) {
				this.m[i][j] = 0
			}
		}
	}

	clone(): Matrix {
		let m = new Matrix(this.height, this.width)
		this.forEach((e, x, y) => {
			m.m[x][y] = e
		})
		return m
	}

	fromArray(m: number[][]) {
		this.m = m
		this.height = this.m.length
		this.width = this.m[0].length
		this.checkIntegrity()
	}
	// FROM
	// TO

	bandwidth() {
		// bandwidth => 0
		//+0|  1  0  0
		// 1|  0  1  0
		// 2|  0  0  1
		// bandwidth => 1
		// 0|  1  0  0
		//+1|  1  1  0
		// 2|  0  0  1
		// bandwidth => 2
		// 0|  1  0  0
		// 1|  0  1  0
		//+2|  1  0  1
		// should normalise bandwidth factor ?
	}

	checkIntegrity() {
		for (let y = 0; y < this.height; y++) {
			if (this.m.length !== this.width) {
				console.error(
					'Matrice',
					this.m,
					'invalid width at index',
					y,
					this.m.length,
					'finded, should be',
					this.width
				)
			}
		}
	}

	forEach(cb) {
		for (let x = 0; x < this.height; x++) {
			for (let y = 0; y < this.width; y++) {
				cb(this.m[x][y], x, y)
			}
		}
	}

	normalise() {
		let max = this.m[0][0]
		let min = this.m[0][0]

		this.forEach((e, x, y) => {
			if (e > max) max = e
			else if (e < min) min = e
		})
		/* set MIN MAX between 0 and 1 */
	}

	slice(x, y, offsetX, offsetY) {
		/* extract a matrice portion */
	}
	past(m, x, y) {
		/* past M into this, at x,y */
	}

	transpose() {
		/* FLIP MATRICE AROUND IT'S DIAGONALE */
		return this
	}

	symmetryze() {
		/* store i+j in [i][j] and into [j][i] */
		this.add(this.clone().transpose())
	}

	add(m) {
		m.forEach((e, x, y) => {
			this.m[x][y] += m[x][y]
		})
	}

	sub(m) {
		// substract M.m dans this.m
	}

	adjacency() {
		//  0  1  0
		//  1  0  1
		//  0  1  0
		//return new matrice(adjacency)
	}
	degree() {
		//  1  0  0
		//  0  2  0
		//  0  0  1
		//return new matrice(degree)
	}
	laplacian() {
		//  1 -1  0
		// -1  2 -1
		//  0 -1 -1
		//return new matrice(laplacian)
	}

	/*
	function switchLines(i, j) {
		for (let x = 0; x < this.height; x++)
		{
			tmp = this.m[x][i];
			this.m[x][i] = this.m[x][j];
			this.m[x][j] = tmp
		}
	}

	function switchColumn(i, j) {
		let tmp = this.m[i]
		this.m[i] = this.m[j]
		this.m[j] = tmp;
		// this.switchLines(i,j)
	}
	 */

	switchOrder(order) {
		let newMatrice = new Array(this.height)
		for (let x = 0, xL = this.m.length; x < xL; x++) {
			newMatrice[x] = this.m[order[x]]
		}

		for (let x = 0, xL = this.m.length; x < xL; x++) {
			let oldLine = newMatrice[x]
			let newLine = new Array(this.width)
			for (let y = 0, yL = this.m.length; y < yL; y++) {
				newLine[y] = oldLine[order[y]]
			}
			newMatrice[x] = newLine
		}
		this.m = newMatrice
		return this
	}

	cuthillMckee() {
		let degrees = []
		let notVisited = []
		let queue = []
		let newOrder = []

		for (let i = 0; i < this.m.length; i++) {
			let count = 0
			for (let j = 0; j < this.m[0].length; j++) {
				count += this.m[i][j]
			}
			degrees.push(count)
		}

		for (let i = 0; i < degrees.length; i++) {
			notVisited.push([i, degrees[i]])
		}

		while (notVisited.length > 0) {
			let minNodeIndex = 0
			for (let i = 0; i < notVisited.length; i++) {
				if (notVisited[i][1] < notVisited[minNodeIndex][1]) {
					minNodeIndex = i
				}
			}

			queue.push(notVisited[minNodeIndex][0])
			notVisited.splice(
				notVisited.findIndex((e) => e[0] === queue[0]),
				1
			)

			// Simple BFS
			while (queue.length !== 0) {
				let toSort = []

				for (let i = 0; i < this.m[0].length; i++) {
					if (
						i != queue[0] &&
						this.m[queue[0]][i] !== 0 &&
						notVisited.findIndex((e) => e[0] === i) !== -1
					) {
						toSort.push(i)
						notVisited.splice(
							notVisited.findIndex((e) => e[0] === i),
							1
						)
					}
				}
				toSort.sort((a, b) => degrees[b] - degrees[a])
				for (let i = 0; i < toSort.length; i++) {
					queue.push(toSort[i])
				}

				newOrder.push(queue.shift())
			}
		}

		this.switchOrder(newOrder)
		return newOrder
	}
}

export default Matrix

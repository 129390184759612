import { FunctionComponent, useState, useEffect, useContext } from "react";
import * as THREE from "three";
import Avial, {AvialType} from "@ledr/ts-client";
import ThreeContext from "../../context/three";
import HudContext  from "../../context/hud";

import { Icon,
	ArrayInput,
	wmContext } from "@ledr/instruments";
import {SubclassIcon} from "../../components/three/components/Label";
import Force from "../../components/three/force/force"

import "./forceHud.scss";


interface ThreeGraphProps {
	offset: number;
	nodes: any;
	links: any;
	onSelect:(any)=>void;
	currentEntity: AvialType.Values.V_Entity;
	selectedEntity: AvialType.Values.V_Entity;
	onSelected: (entities: AvialType.Values.V_Array) => void;
	rootEntity: AvialType.Values.V_Entity;
	classes: any;
	setClasses: (index: number, content: any) => void;
}

const ThreeGraph: FunctionComponent<ThreeGraphProps> = (props) => {
	const three = useContext(ThreeContext);
	const hud = useContext(HudContext)
	const [filtersLoc, setFiltersLoc] = useState(new Avial.Values.V_Array([]));

	const [force, setForce] = useState<Force|undefined>()

	const [linkStrength, setlinkStrength] = useState(2);
	const [repulsion, setRepulsion] = useState(2);
	const [gravity, setGravity] = useState(0.01);
	const [passes, setPasses] = useState(5);
	const [childFree, setChildFree] = useState(true);

// NON HOOK PROTECTED
	force?.update(props.nodes)
// NON HOOK PROTECTED

	useEffect( ()=>{
		hud.declare([
			{
				name: "Classes",
				x: "center",
				y: "center",
				widget: (data)=>


					<div className="classElementList" >
						{data?.classes.map((c,i) => (
							<div
								className={`classElement ${c.display ? "": "hidded"}`}

								onMouseEnter={()=>{
									three.nodes2.forEach(n => {
										n.JSXNode.div.style.opacity = "0.1"
										if (n.data.class === c.class)
											n.styles.activate("highlight")
								})

								three.nodes2.forEach(n => {
									if (n.data.class === c.class)
										n.styles.activate("highlight")
								})

								}}

								onMouseLeave={()=>{
									three.nodes2.forEach(n => {
										n.styles.desactivate("highlight")
								})
								}}
					>
								<div
									style={{
										margin: "1px",
										display: "flex",
										alignItems: "center",
										width:"25px"
									}}
								>
									<SubclassIcon class={c.class} size={25}/>
								</div>

								<div style={{
									width:"100%",
										justifyContent:"space-between",
										display: "flex", alignItems: "center",
									}}>

									<div
										style={{
											padding: "0px 5px"
										}}
									>
										{c?.class?.split("_")[0]}
									</div>
									<div>
										{c.count}
									</div>
								</div>

								<div
									style={{
										display: "flex",
											alignItems: "center",
											cursor:"pointer",
											justifyContent: "right"
									}}
									onClick={()=>{ data.setClasses(i, {display: !c.display}) }}
								>
									{c.display
										? <Icon size={20} name={"AiOutlineEye"} />
										: <Icon size={20} name={"AiOutlineEyeInvisible"} />
									}
								</div>

								<div
									style={{
										display: "flex",
											alignItems: "center",
											cursor:"pointer",
											justifyContent: "right"
									}}
									onClick={()=>{
										three.nodes2.forEach(n => {
											if (n.data.class === c.class)
											{
												if (!c.edge)
												{
													n.styles.activate("edge")
													n.isEdge = true;
									}
									else
									{
										n.styles.desactivate("edge")
										n.isEdge = false;
									}
									}
									})
									data.setClasses(i, {edge: !c.edge})
									}}
								>

									{c.edge
										? <Icon size={20} name={"BiShapePolygon"} style={{color:"white"}}/>
										: <Icon size={20} name={"BiShapePolygon"} style={{color:"grey"}}  />
									}

								</div>

							</div>
						))}
					</div>

			},
			{
				name: "Info",
				y: "top",
				x: "right",
				widget: (data)=>
					<>
						Nodes : {data?.nodes}
						<br/>
						Links : {data?.links}
						<br/>

					</>
			},

			{
				name: "Force",
				y: "top",
				x: "right",
				widget: (data)=>
					<>
						PASSES [{data?.passes}]
						<input
							type="range"
							min={0}
							max={100}
							step={1}
							value={data?.passes}
							onChange={(evt)=> setPasses(Number(evt.target.value))}
						/>

						GRAVITY [{data?.gravity}]
						<input
							type="range"
							min={0}
							max={0.1}
							step={0.001}
							value={data?.gravity}
							onChange={(evt)=> setGravity(Number(evt.target.value))}
						/>
						REPULSION [{data?.repulsion}]
						<input
							type="range"
							min={0}
							max={10}
							step={0.01}
							value={data?.repulsion}
							onChange={(evt)=> setRepulsion(Number(evt.target.value))}
						/>
						LINK STRENGTH [{data?.linkStrength}]
						<input
							type="range"
							min={0}
							max={10}
							step={0.01}
							value={data?.linkStrength}
							onChange={(evt)=> setlinkStrength(Number(evt.target.value))}
						/>
					</>
			},
			{
				name: "Filters",
				y: "top",
				x: "right",
				widget: (data)=>
						<div style={{ padding:"5px", }}>

						Show Child Free: <input
							style={{
								height: "20px",
								width: "20px"
							}}
							type="checkbox"
							value={data?.childFree}
							onChange={()=> setChildFree(!data?.childFree)}
							onMouseEnter={()=>{
								three.nodes2.forEach(n => {
									n.JSXNode.div.style.opacity = "0.1"
									if (n.linkChildren.length !== 0)
										n.styles.activate("highlight")
								})

								three.nodes2.forEach(n => {
								})

							}}

							onMouseLeave={()=>{
								three.nodes2.forEach(n => {
									n.styles.desactivate("highlight")
							})
							}}
						/>

							{data?.filtersLoc &&
							<ArrayInput value={data?.filtersLoc} onChange={(v)=>{
								setFiltersLoc(v)
								}} />	
							}

						</div>
			},
		])

	}, [])

	useEffect(()=>{
		if (!force)
			return
		force.opts= { ...force.opts, passes, linkStrength, repulsion, gravity};
		hud.setData("Force", force.opts)
	}, [force, passes, linkStrength, repulsion, gravity])

	useEffect(()=>{
		if (!force)
			return
		hud.setData("Classes", {classes: props.classes, setClasses: props.setClasses})
	}, [force, props.classes, props.setClasses])

	useEffect(()=>{

		if (!force)
			return

		hud.setData("Info", {
			nodes: props.nodes.length,
			links: props.links.length,
		})

	}, [force, props.nodes, props.links, childFree])

	useEffect(()=>{

		if (!force)
			return
		force.opts= { ...force.opts, childFree};

		hud.setData("Filters", {
			nodes: props.nodes.length,
			links: props.links.length,
			childFree: childFree,
			filtersLoc: filtersLoc
		})
		//force.hide();

	}, [force, props.nodes, props.links, childFree, filtersLoc])

	useEffect(() => {
		if (!force)
			setForce(new Force(three, props.rootEntity, props.offset))
		return ()=>{ force?.destruct() }
	}, []);

	useEffect(() => {
		if (three.selectedEntity)
			props.onSelect(three.selectedEntity)
	}, [three.selectedEntity]);

	useEffect(() => {
		if (three.selectedEntities)
			props.onSelected(three.selectedEntities)
	}, [three.selectedEntities])

	useEffect(() => {
		if(force)
			force.rootEntity = props.rootEntity;
		three.simpleCamera.newTarget(new THREE.Vector3());
	}, [props.rootEntity]);


	useEffect(() => {
		force?.update(props.nodes)
	}, [props.nodes]);

	useEffect(() => {

		if (!force) return;
		let node = force.nodes.find((n) => n.data.pid === props.currentEntity.toString() );
		if (!node) { 
			console.log("SELECTED NODE NOT IN GRAPH")
			return
		}

		//@ts-ignore
		if (node && node.group)
		{

			force.nodes.forEach((n) => n.styles.desactivate("selected"))
			node.styles.activate("selected")
			let t = new THREE.Vector3(0, 0, 0);
			//@ts-ignore
			node.group.getWorldPosition(t);
			three.simpleCamera.newTarget(t);
		}

	}, [force, props.currentEntity]);

	return ( <>
	</>);
};

export default ThreeGraph;

import * as THREE from 'three'
import Avial from '@ledr/ts-client'

import ViewportElement from '../ViewportElement'
import ForceNode from '../force/forceNode'
import ForceLink from '../force/forceLink'

//import ForceLink from './forceLink';
// import Edge from './edge';

const level = {
	NULL_CLASS: 0,
	SERVER_CLASS: 1,
	RESTAURANT_CLASS: 2,
	AVESTERRA_CLASS: 3,
	HOTEL_CLASS: 4,
	LIST_CLASS: 5,
	ADAPTER_CLASS: 6,
	INSTRUMENT_CLASS: 7,
	REGISTRY_CLASS: 0,
	CITY_CLASS: 10,
	IDENTITY_CLASS: 11,
	FOLDER_CLASS: 12,
	SPACE_CLASS: 13,
	PERSON_CLASS: 14,
	FAMILY_CLASS: 15,
	EXAMPLE_CLASS: 16,
	TABLE_CLASS: 17,
	AIRPORT_CLASS: 18,
	EMPLOYEE_CLASS: 19,
	HOUSEKEEPING_CLASS: 20,
	ROOM_CLASS: 21,
	LOCATION_CLASS: 22,
	ACCOUNT_CLASS: 23,
	RECEPTION_CLASS: 24,
	TEST_CLASS: 25,
	COUNTRY_CLASS: 26,
	PUBLICATION_CLASS: 27,
	TIMER_CLASS: 28,
	CATEGORY_CLASS: 29,
	ANALYTIC_CLASS: 30,
	TEMPLATE_CLASS: 31,
	VEHICLE_CLASS: 32,
	FEATURE_CLASS: 33,
	UNIVERSITY_CLASS: 34,
	CONFERENCE_CENTER_CLASS: 35,
	RELATIONSHIP_CLASS: 36,
	BUSINESS_CLASS: 37,
	TEAM_CLASS: 38,
	EVENT_CLASS: 39,
	MONEY_CLASS: 40,
	TRANSACTION_CLASS: 41,
	FILTER_CLASS: 42,
	// PATCH FOR MISSING CLASSES TAXO
}
// EXTENDS OVER ORCHESTRA THREE CLASS
class Nodes extends ViewportElement {
	nodes: ForceNode[]
	links: ForceLink[]
	isFlat = true

	constructor(three) {
		super(three)
		this.nodes = []
		this.links = []
		//this.initView();
	}

	getNodeById(pid) {
		return this.nodes.find((n) => {
			n.data.pid === pid
		})
	}

	updateNodes(nodes) {
		// REMOVE OLD
		this.nodes = this.nodes.filter((node) => {
			if (!nodes.find((n) => n.pid === node.data.pid)) {
				node.destruct()
				this.group.remove(node.group)
				return false
			}
			return true
		})

		// ADD NEW
		nodes
			.filter((n) => !this.nodes.find((nn) => nn.data.pid === n.pid))
			.forEach((node) => {
				//this.three.nodes.getNodeById()

				let newNode = new ForceNode(
					{
						pos: {
							x: Math.random() - 0.5,
							y: Math.random() - 0.5,
							z: 0, //(Math.random() - 0.5)
						},
						data: {
							name: node.name,
							class: node.class,
							category: node.category,
							pid: node.pid,
						},
					},
					this.three
				)

				this.nodes.push(newNode)
				this.group.add(newNode.group)
			})

		//@ts-ignore
		this.three.nodes2 = this.nodes
	}

	updateLinks(links) {
		// REMOVE OLD
		this.links = this.links.filter((link) => {
			let nodeSource = this.nodes.find((n) => n.data.pid === link.src)
			let nodeTarget = this.nodes.find((n) => n.data.pid === link.src)
			if (!nodeSource || !nodeTarget) {
				link.destruct()
				this.group.remove(link.group)
				return false
			}
			return true
		})

		// ADD NEW
		links.forEach((l) => {
			let nodeSource = this.nodes.find((n) => n.data.pid === l.src)
			let nodeTarget = this.nodes.find((n) => n.data.pid === l.dst)
			if (nodeSource && nodeTarget) {
				if (
					this.links.find(
						(ll) => ll.src === nodeSource && ll.dst === nodeTarget
					)
				)
					return
				let link = new ForceLink(
					nodeSource,
					nodeTarget,
					l.locutor,
					this.three
				)
				this.links.push(link)
				this.group.add(link.group)
			}
		})

		//@ts-ignore
		this.three.links2 = this.links
	}

	async update(nodes: any[], links: any[]) {
		console.log('UPDATE')

		this.updateNodes(nodes)
		this.updateLinks(links)

		this.nodes.forEach((n) => {
			n.initView()
		})

		//this.hide()
		// nodes.links = new Links() ?
		// REFRESH LAYERS.INIT TO REFRESH LAYERS.
		// layers.forEach(l.init(nodes))
	}

	destruct() {}

	switchFlat() {
		this.isFlat = !this.isFlat
	}

	applyLayers(n) {
		/*
			this.layers = [
				{
					name: "byClass",
					scale: 1,
					opts: {},
					init: {},
					var: {},
					projectMatrice: (n, var) => {
						let id = var.classes.findIndex(c => c === n.class)
						if (n.class === 0)
							n.pos.z = id;
						}
				}
			];

			this.layers.forEach(l => {
				n.position.project(l.projectMatrice(n))
			})
			n.nextPos()
		*/
	}

	animate(d) {
		super.animate(d)

		this.nodes.forEach((n) => {
			this.applyLayers(n)
			if (!this.isFlat) n.nextPos.setZ(-(level[n.data.class] ?? 0) / 5)

			n.animate(d)
		})

		this.links.forEach((l) => {
			l.animate(d)
		})
	}
}

export default Nodes

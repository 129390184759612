import * as THREE from 'three'
import { ThreeInit } from '../init'
import ForceNode from './forceNode'
import ForceLink from './forceLink'
import forceSimu from './forceSimu'

import { AvialType } from '@ledr/ts-client'

import Worker2 from './monWorker.worker.js'
function workerLoader(url) {
	try {
		// UGLY WEPACK HACK
		// NEED IMPORT AND INSTANCIATION (if not instancied, not unused, not imported)
		// TO FORCE WEBPACK GENERATING ENDPOINT WITH RIGHT COMPILATION (not "system")
		// WILL CORS FAIL
		// TODO : setup webpack to serve correctly "web workers" (in instrument repo should be perfect)
		new Worker2()
	} catch (e) {}

	// DOWNLOAD BLOB WORKER (aside CORS) THEN LOAD IT
	const content = `importScripts( "${url}" );`
	const worker_url = URL.createObjectURL(
		new Blob([content], { type: 'text/javascript' })
	)
	return new Worker(worker_url)
}
/*
		try {
			const url = 'http://192.168.1.19:9999/monWorker.worker.worker.js'
			const worker = workerLoader(url)
			worker.onmessage = (evt) =>
				console.log('Worker has send :', evt.data)

			//	SHARED_ARRAY_BUFFER NEED CORS (because of spectre/meltdown, cpu archi issue)
			//	Cross-Origin-Opener-Policy: same-origin
			//	const sab = new SharedArrayBuffer(1024)
			//	worker.postMessage(sab)
		} catch (e) {
			console.log('worker error: ', e)
		}
*/
class Force {
	group: THREE.Group
	nodes: any
	links: any

	offset: any

	interval: any
	three: ThreeInit

	selectedEntity: AvialType.Values.V_Entity
	rootEntity: AvialType.Values.V_Entity

	opts = {
		passes: 5,
		repulsion: 0.1,
		gravity: 0.1,
		linkStrength: 0.1,
		childFree: true,
	}

	constructor(three, root, offset) {
		console.log('BUILD FORCE ')
		this.three = three
		this.group = new THREE.Group()
		this.rootEntity = root
		this.offset = offset
		this.nodes = []
		this.links = []

		three.addChildren({
			group: this.group,
			animate: this.animate.bind(this),
		})
	}

	destruct() {
		clearInterval(this.interval)
		this.three.removeChildren(this.group)
	}

	animate(d) {
		const t0 = performance.now()
		forceSimu.standard(
			this.nodes.filter((n) => !n.hidden),
			this.opts
		)
		/*
		const t1 = performance.now()
		forceSimu.edges(
			this.nodes.filter((n) => n.isEdge),
			this.opts
		)
		*/

		this.nodes.forEach((n) => n.nextPos.setZ(-this.offset))

		const t2 = performance.now()

		let root = this.nodes.find((n) => {
			return n.data.pid === this.rootEntity.toString()
		})

		if (root) {
			root.nextPos = new THREE.Vector3(0, 0, 0)
			root.group.position.set(0, 0, 0)
		}

		/*
		console.log(t1 - t0, 'forceStandard')
		console.log(t2 - t1, 'forceEdge')
		*/
	}

	hide() {
		console.trace('HIDE')
		this.three.nodes.nodes.forEach((n) => {
			if (this.opts.childFree && n.linkChildren.length === 0) {
				n.hidden = true
				n.group.traverse(function (child) {
					child.visible = false
				})

				n.linkParents.forEach((l) => {
					l.group.traverse(function (child) {
						child.visible = false
					})
				})
			} else {
				n.hidden = false
				n.group.traverse(function (child) {
					child.visible = true
					n.linkParents.forEach((l) => {
						l.group.traverse(function (child) {
							child.visible = true
						})
					})
				})
			}
		})
	}

	async update(nodes: any[]) {
		console.log('UPUPUP', nodes)
		this.nodes = this.three.nodes.nodes.filter((node) =>
			nodes.find((n) => n.pid === node.data.pid)
		)
		//this.links = this.three.nodes.links
		this.hide()
	}
}

export default Force

import * as THREE from "three";

import { ConvexHull } from 'three/examples/jsm/math/ConvexHull.js';
import ForceNode from "./forceNode";
import ViewportElement from "../ViewportElement";


import {
	BufferGeometry,
	Float32BufferAttribute
} from 'three';
import {iconClassColor} from "../../../style/icons";
//import { ConvexHull } from '../math/ConvexHull.js';

function calculerCentre(vecteurs) {
	var centre = new THREE.Vector3();
	vecteurs.forEach(v => { centre.add(v) })
	centre.divideScalar(vecteurs.length);
	return centre;
}

class ConvexGeometry extends BufferGeometry {

	constructor( points = []) {
		super();

		if (points.length < 4)
			return

		// buffers
		const vertices = [];

		const convexHull = new ConvexHull();

		points.forEach(p => p.setZ(Math.random()))
		convexHull.setFromPoints( points );

		// generate vertices and normals

		const faces = convexHull.faces;

		for ( let i = 0; i < faces.length; i ++ ) {

			const face = faces[ i ];
			let edge = face.edge;

			// we move along a doubly-connected edge list to access all face points (see HalfEdge docs)

			do {
				const point = edge.head().point;
				vertices.push( point.x, point.y, 0);
				edge = edge.next;
			} while ( edge !== face.edge );

		}
		this.setAttribute( 'position', new Float32BufferAttribute( vertices, 3 ) );
	}
}


class Edge extends ViewportElement {
	node: ForceNode;
	convexHull: ConvexHull;
	mesh: THREE.Mesh;
	nodes: THREE.Vector3[];
	color: THREE.Color;
	material: THREE.Material;
	center: THREE.Group;

	constructor(obj: ForceNode, three) {
		super(three)
		this.node = obj;
		this.center = new THREE.Group();

		this.group.renderOrder = 0;
		this.color = new THREE.Color( 0x000000 ).setHSL(
			iconClassColor( this.node.data.class ?? "") / 360,
			.8,
			.3
		);


		this.material = new THREE.MeshBasicMaterial( {
			side: THREE.DoubleSide,
			color: this.color,
			transparent: true,
			opacity: 0.3,
		} );


		let vertices = this.node.linkChildren.map(e =>
			e.dst.group.position.clone().sub(this.node.group.position)
		)

		const geometry = new ConvexGeometry(vertices);
		this.mesh = new THREE.Mesh( geometry, this.material );
		this.group.add(this.mesh)	

	}

	remove(){
		this.mesh.geometry.dispose();
		this.material.dispose();
		console.log("removeEdge")
	}

	animate(){

		let vertices = this.node.linkChildren.map(e =>
			this.group.worldToLocal(e.dst.group.position.clone())
			//e.dst.group.position.clone().sub(this.node.group.position)
		)

		if (vertices.length < 4)
			return

		/*
		let centre = calculerCentre(vertices)
		this.center.position.set(centre.x, centre.y, centre.z)
		 */
		this.mesh.geometry.dispose();
		this.mesh.geometry = new ConvexGeometry(vertices);
		this.mesh.scale.setScalar(1.2)

		//this.mesh = new THREE.Mesh( geometry, this.material );

	}
}

export default Edge;


//@ts-expect-error
import { notif } from "@ledr/layout";
import { FunctionComponent, useState, useEffect, useMemo, useContext } from "react";
import  useEntity  from "../../hook/entity";
import  InstrumentsLinkerContext from "../../context/instrumentsLinker";
import { useSelector } from "react-redux";
import { AppState } from "../../store/types";
import Loading from "../../components/utilities/Loading";
import Error from "../../components/utilities/Error";
import EntityForm from "../../components/form/Entity";
import Avial from "@ledr/ts-client";
import { AvialType } from "@ledr/ts-client";

import {
	Print,
	SidebarMenu,
	T_SidebarCategories,
	wmContext,
	WindowChild,
	Metadata,
	Tooltip,
} from "@ledr/instruments";
import { PrintEntityStore } from "../../hoc/connectedComponents";
import BarChart from "./BarChart";

interface StoreProps {}

const Store: FunctionComponent<StoreProps> = (props) => {
	const MyContext = useContext(InstrumentsLinkerContext); 
	const wm = useContext(wmContext); 

	const [selectedEntity, setSelectedEntity] = useState(new Avial.Values.V_Entity(
		//@ts-ignore
		wm.win?.data?.entity ?? "<1>"
	));
	const [history, setHistory] = useState([]);

	useEffect(()=> {
		MyContext?.out?.["currentEntity"]?.(selectedEntity)
	}, [ MyContext?.out?.["currentEntity"], selectedEntity ])

	useEffect(()=>{
		MyContext.declareInOut( {
			in : [ {port: "currentEntity", setter: setSelectedEntity }, ],
			out :[
				{port: "currentEntity" },
				{port: "selectedEntity" },
			]
		})	
		return () => { MyContext.unDeclareInOut() }
	}, [])


	const [options, setOptions] = useState({
		isValueTypeDisplayed: false,
		isEdit: false,
		isDebugPrinter: false,
		isDisplayRaw: false,
	});


	const entityStore = useEntity(selectedEntity);
	useEffect(()=>{

		let newHistory = [selectedEntity, ...history.filter(h => !h.equals(selectedEntity) ) ];
		newHistory.splice(15, 10)
		setHistory(newHistory)

	}, [selectedEntity])



	const tab = useMemo(
		() => (
			<>
				<div>
				</div>
				<div style={{display: "flex"}}>
					<EntityForm entity={selectedEntity} onSubmit={setSelectedEntity} />
				</div>
				<div>
				</div>
			</>
		),
		[selectedEntity]
	);
	const win = useMemo(() =>
		<Loading
			isLoading={entityStore.isLoadingMetadata}
			message={`Loading ${selectedEntity.toString()} Metadata`}
		>
			<Loading
				isLoading={entityStore.isLoadingContents}
				message={`Loading ${selectedEntity.toString()} Contents`}
			>

				<Error error={ entityStore.errorContents } >

							 <BarChart entityData={entityStore.contents} />


				</Error>
			</Loading>
		</Loading>
		, [
			options,
			entityStore,
			MyContext.out?.["selectedEntity"]
		]);
	return <WindowChild tab={tab}>{win}</WindowChild>;
};

export default Store;

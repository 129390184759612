import React, { FunctionComponent } from 'react'
import Avial, {AvialType} from '@ledr/ts-client'
import { ValueInput, ValueInputStatic, Icon } from '@ledr/instruments'

interface TableProps {
	table: AvialType.Table;
	onChange: (newTable: AvialType.Table) => void;
}

const Table: FunctionComponent<TableProps> = (props) => {
	console.log(props.table)

	const addColumn = () => { props.table.addColumn();
		props.onChange(new Avial.Model.Table(props.table.columns, props.table))
	}

	const changeColumnName= (name, columnId) => {
		props.table.columns[columnId].name= name;
		props.onChange(new Avial.Model.Table(props.table.columns, props.table))
	}

	const changeDefaultColumn = (value, id) => {
		props.table.changeDefaultColumn(value, id);
		props.onChange(new Avial.Model.Table(props.table.columns, props.table))
	}

	const removeColumn= (id) => {
		props.table.removeColumn(id);
		props.onChange(new Avial.Model.Table(props.table.columns, props.table))
	}

	const addRow= () => {
		props.table.addRow();
		props.onChange(new Avial.Model.Table(props.table.columns, props.table))
	}

	const changeRowKey= (key, rowId) => {
		props.table[rowId].Key = key;
		props.onChange(new Avial.Model.Table(props.table.columns, props.table))
	}

	const changeValue= (value, rowId, columnId) => {
		props.table[rowId][columnId] = value;
		props.onChange(new Avial.Model.Table(props.table.columns, props.table))
	}

	const deleteRow = (id) => {
		props.onChange(new Avial.Model.Table(
			props.table.columns,
			[
				...props.table.slice(0, id),
				...props.table.slice(id + 1)
			])
		)
	}

	return (
		<div style={{
			display: "grid",
				gridTemplateColumns:`auto repeat(${props.table.columns.length}, 1fr) min-content`,
				width: "100%",
			}}>

			<div >
			</div>
			{ props.table.columns.map((column, id) =>
			<div className={"cellHead"} style={{display:"flex", flexDirection: "column"}}>
			<div style={{margin: "5px", display:"flex"}}>

					<input
						type="text"
						style={{ width: "100%", height: "22px", margin:"1px" }}
						value={column.name}
						onChange={(evt)=> changeColumnName(evt.target.value, id)}
						placeholder={`Column #${id} name`}
					/>

				<button
					style={{
						background: "rgba(255,0,0,0.2)",
							color: "rgba(255,0,0,1)",
							padding: "1px",
							margin: "1px"
					}}
					onClick={ () => { removeColumn(id)}
					}
				>
					<Icon name={"MdOutlineCancel"} size={20} />
				</button>
				</div>
				<ValueInput
					value={column.default}
					onChange={(value)=>changeDefaultColumn(value, id)}
				/>
			</div>
			) }

			<div className={"cellHead"}>
				<button
					style={{
						background: "rgba(0,255,0,0.2)",
							color: "rgba(0,255,0,1)",
							padding: "1px",
							width:"100%"
					}}
					onClick={()=>{addColumn()}}
				>
					<Icon name={"MdAddCircleOutline"} size={20}/>
				</button>
			</div>

			{ props.table.map((row,rowId) =>
			<>
				<div style={{whiteSpace:"nowrap"}}>
					<input
						type="text"
						style={{
							minWidth: "100px",
							maxWidth: "200px",
								width: "100%", height: "22px", margin:"1px" }}
						value={row.Key}
						onChange={(evt)=> changeRowKey(evt.target.value, rowId)}
						placeholder={`Row #${rowId} Key`}
					/>
				</div>
				{ row.map((cell, columnId) =>
				<ValueInputStatic value={cell} onChange={(value)=>{ changeValue(value, rowId, columnId)}} />
				)}

				<button
					style={{
						background: "rgba(255,0,0,0.2)",
							color: "rgba(255,0,0,1)",
							padding: "1px",
							margin: "1px"
					}}
					onClick={ () => { deleteRow(rowId) } }
				>
					<Icon name={"MdOutlineCancel"} size={20} />
				</button>
			</>
			) }


				<button
					style={{
						gridColumn: `1 / ${props.table.columns.length + 2}`,
						background: "rgba(0,255,0,0.2)",
						color: "rgba(0,255,0,1)",
						padding: "1px",
						width:"100%"
					}}
					onClick={ () => { addRow() } }
				>
					<Icon name={"MdAddCircleOutline"} size={20}/>
				</button>


		</div>
	)
}

export default Table;

import Avial from "@ledr/ts-client";
import { FunctionComponent, useContext, useState, useMemo, useEffect } from "react";
import  InstrumentsLinkerContext from "../../context/instrumentsLinker";
import {EntityInput, WindowChild } from "@ledr/instruments";

import ApiContext from "../../context/api";

import Toolbox from "../../components/hud/Toolbox";
import Leaflet from "./Leaflet";
//import Three from "../../components/three/Three";
import SpaceThree from "./SpaceThree"

import "./Maps.scss";

		let level0 = [
			{
				data:{
					name:"DemoNeom",
					entity: new Avial.Values.V_Entity("<0|0|100087>"),
					category:"TEST_CATEGORY",
					class:"TEST_CLASS"
				},
				coordinates:[-41.9236901,79.3839049,0]
			},
		];
		let level1 = [
			{
				data: {
					name:"Singapor",
					entity: new Avial.Values.V_Entity("<0|0|100103>"),
					category:"COUNTRY_CATEGORY",
					class:"COUNTRY_CLASS"
				},
				coordinates:[ 103.76185 ,1.3139987]
			},
			{
				data: {
					name:"France",
					entity: new Avial.Values.V_Entity("<0|0|100088>"),
					category:"COUNTRY_CATEGORY",
					class:"COUNTRY_CLASS"
				},
				coordinates:[2.3705102,48.4000325,0]
			},
			{
				data: {
					name:"United States",
					entity: new Avial.Values.V_Entity("<0|0|100089>"),
					category:"COUNTRY_CATEGORY",
					class:"COUNTRY_CLASS"
				},
				coordinates:[-101.3645027,40.7805414,0]
			},
			{
				data: {
					name:"Saudi Arabia",
					entity: new Avial.Values.V_Entity("<0|0|100090>"),
					category:"COUNTRY_CATEGORY",
					class:"COUNTRY_CLASS"
				},
				coordinates:[43.3414240,25.0855989,0]
			},
			{
				data: {
					name:"Japan",
					entity: new Avial.Values.V_Entity("<0|0|100091>"),
					category:"COUNTRY_CATEGORY",
					class:"COUNTRY_CLASS"
				},
				coordinates:[138.9733535, 37.1471821,0]
			}
		];

	let level2 = [


			{
				data:{
					name:"Marina Bay Sands",
					entity: new Avial.Values.V_Entity("<0|0|100129>"),
					category:"HOTEL_CATEGORY",
					class:"HOTEL_CLASS"
				},
				coordinates:[
					103.8591213,
					1.2837031,
					]
			},

			{
				data:{
					name:"The Plaza",
					entity: new Avial.Values.V_Entity("<0|0|100092>"),
					category:"HOTEL_CATEGORY",
					class:"HOTEL_CLASS"
				},
				coordinates:[ -73.974121, 40.764778,0]
			},
			{
				data:{
					name:"The Beverly Hills",
					entity: new Avial.Values.V_Entity("<0|0|100093>"),
					category:"HOTEL_CATEGORY",
					class:"HOTEL_CLASS"
				},
				coordinates:[-118.4159779, 34.0818134]
			},
			{
				data:{
					name:"Ritz Paris",
					entity: new Avial.Values.V_Entity("<0|0|100094>"),
					category:"HOTEL_CATEGORY",
					class:"HOTEL_CLASS"
				},
				coordinates:[ 2.3290219557899965,48.86828213151135,  ]
			},
			{
				data:{
					name: "Hôtel Dieu",
					entity: new Avial.Values.V_Entity("<0|0|100095>"),
					category:"HOTEL_CATEGORY",
					class:"HOTEL_CLASS"
				},
				coordinates:[ 5.370469754400232,43.29872967498277,  ]
			},


			{
				data:{
					name: "Makkah Clock Royal Tower",
					entity: new Avial.Values.V_Entity("<0|0|100096>"),
					category:"HOTEL_CATEGORY",
					class:"HOTEL_CLASS"
				},
				coordinates:[ 39.82591282750996,21.418233213531916,  ]
			},
			{
				data:{
					name: "Ritz-Carlton",
					entity: new Avial.Values.V_Entity("<0|0|100097>"),
					category:"HOTEL_CATEGORY",
					class:"HOTEL_CLASS"
				},
				coordinates:[47.109750670641425, 24.7478866963607]
			},
			{
				data:{
					name: "The Oberoi",
					entity: new Avial.Values.V_Entity("<0|0|100098>"),
					category:"HOTEL_CATEGORY",
					class:"HOTEL_CLASS"
				},
				coordinates:[39.61429999142286, 24.47161103259494 ]
			},
			{
				data:{
					name: "Aman",
					entity: new Avial.Values.V_Entity("<0|0|100099>"),
					category:"HOTEL_CATEGORY",
					class:"HOTEL_CLASS"
				},
				coordinates:[139.7653570750273, 35.68568415983242 ]
			},

	];


	let pMock = [level0, level0, level1,level1, level1, level2, level2,level2,level2, level2, level2,level2, level2, level2, level2,level2];


interface MapsProps {}

function computeLevel(Ax, Ay, Bx, By){
	Ax = Math.max(Math.min(Ax, 180), -180)
	Ay = Math.max(Math.min(Ay, 90), -90)
	Bx = Math.max(Math.min(Bx, 180), -180)
	By = Math.max(Math.min(By, 90), -90)

	let level = 0n;

	let deltaX = Bx - Ax;
	let deltaY = By - Ay;

	for (let i = 0, target = 0; target === 0; i++)
	{
		console.log("LEVEL", deltaX, 180 / Math.pow(2, i), i)

		if (deltaX < 180 / Math.pow(2, i))
			level++;
		else
			target = 1;
	}
		console.log("LEVEL ----")
	if (level > 20)
		level = 0n;
	return { Ax, Ay, Bx, By, level};
}

const Maps: FunctionComponent<MapsProps> = () => {
	const MyContext = useContext(InstrumentsLinkerContext); 
	const api = useContext(ApiContext);

	const [space, setSpace] = useState(new Avial.Values.V_Entity("<111>"));
	const [points, setPoints] = useState([]);
	const [computedLevel, setComputedLevel] = useState(0n);

	const [volume, setVolume] = useState([0,0,0,0,0,0]);
	const [autoLoad, setAutoLoad] = useState(true);

	const [showThree, setShowThree] = useState(false);
	const [showWorld, setShowWorld] = useState(true);
	const [zoom, setZoom] = useState(3);

	const [position, setPosition] = useState({
		latitude: 40,
		longitude: 0,
		zoom: 3.0,
	});

	const load = () =>  {

	 let {Ax, Ay, Bx, By, level} = computeLevel(volume[0],volume[1],volume[3],volume[4]);
		
		let opts = {
			entity: space.value,
			method: Avial.Taxonomy.Method.RETRIEVE,
			authorization: api.keychain,

			// parameter: 2n,
			//WORKING //value: { type: "INTERCHANGE", value: "[-180.0,-90.0,0,180.0,90.0,7]"},

			parameter: level,
			value: { type: "INTERCHANGE", value: `[${Ax}, ${Ay}, 0, ${Bx}, ${By}, 10]`},
		}

		api.session.invokeEntity(opts)
			.then((data) => {
				if (data.isError())
					console.error(data);
				else
				{
					let res = JSON.parse(data?.value?.value)?.Properties
					console.log("API RESPONSE",res)

					setPoints(res?.map(e => ({
						data: {
							name: e[1],
							entity: e[2]["ENTITY"],
							category: e[3]["CATEGORY_ATTRIBUTE"]["STRING"],
							class: e[3]["CLASS_ATTRIBUTE"]["STRING"]
						},
						coordinates: JSON.parse( e[3]["COORDINATE_ATTRIBUTE"]["INTERCHANGE"]).map( i => i.toFixed(7))
					})
					));

				}

			})
			.catch((err) => {
				console.error(err);
			});
	}
	/*
	useEffect(()=>{
		console.log("ZOOM", Math.floor(zoom))
		setPoints(pMock[Math.floor(zoom)]);
	}, [zoom])
		*/

	useEffect(()=>{
		setComputedLevel(computeLevel(volume[0],volume[1],volume[3],volume[4]).level)
		if (autoLoad) load();
	}, [volume, autoLoad])



	let newPoint =  async (entity, name, coordinate, category, klass)=>{
/*
		await api.session.invokeEntity({
			authorization: api.keychain,
			entity : space.value,
			method: Avial.Taxonomy.Method.EXCLUDE,
			parameter: BigInt(computedLevel),
			key: name,
			name: name,
			auxiliary: entity.value,
			value     : Avial.Utils.Value.fromTagAndString(Avial.Taxonomy.Tag.INTERCHANGE, "[0,0,0]" ),
		})
 */
console.log(coordinate)
		await api.session.invokeEntity({
			method: Avial.Taxonomy.Method.INCLUDE,
			context	: Avial.Taxonomy.Context.AVESTERRA,
			entity : space.value,
			auxiliary: entity.value,
			authorization: api.keychain,
//		name: name,
			key: name,

			parameter: BigInt(computedLevel), // WARNING THIS DON'T WORK
			value     : Avial.Utils.Value.fromTagAndString(Avial.Taxonomy.Tag.INTERCHANGE, JSON.stringify(coordinate) ),

			// WORKING parameter: 2n, //BigInt(computedLevel), // WARNING THIS DON'T WORK
			// WORKING value     : Avial.Utils.Value.fromTagAndString(Avial.Taxonomy.Tag.INTERCHANGE, "[1.00000000000001, 1.0000000000000001, 7]"), // JSON.stringify(coordinate)),

			//class: klass,
			//category: category,
			//		state,
			//		condition,

		})
			.finally(() => {
				load()
			})
	};

	let deletePoint =  async (entity, name, coordinate, category, klass)=>{
		console.log("DELETE", entity, name, coordinate, category, klass)
		await api.session.invokeEntity({
			entity : space.value,
			method: Avial.Taxonomy.Method.EXCLUDE,
			parameter: BigInt(computedLevel),
			key: name,
			name: name,
			auxiliary: entity.value,
			value     : Avial.Utils.Value.fromTagAndString(Avial.Taxonomy.Tag.INTERCHANGE, JSON.stringify(coordinate)),
			authorization: api.keychain,
		})
		load()
	}


	useEffect(()=> {
		MyContext?.out?.["points"]?.(points)
	}, [ MyContext?.out?.["points"], points ])

	useEffect(()=>{
		MyContext.declareInOut( {
			in : [
				//		{port: "points", setter: setPoints},
			],
			out :[
				{port: "selectedEntity" },
				{port: "points"},
				{port: "volume" },
			]
		})
		return () => { MyContext.unDeclareInOut() }
	}, [])


	const moveEnd = (volume, target, zoom)=> {
		setVolume(volume)
		MyContext?.out?.["volume"]?.(volume)
		setZoom(zoom)
	}



	let selected= (entity) => {
		MyContext?.out?.["selectedEntity"]?.(new Avial.Values.V_Entity(entity))
	}

	const tab = useMemo(() =>
		<div style={{display: "flex", justifyContent:"space-between", width:"100%"}}>

		<div >
			<button
				className={autoLoad ? "active" : ""}
				onClick={()=>{setAutoLoad(!autoLoad)}}
			>
				AutoLoad
			</button>
		</div>

		<div >
			<EntityInput value={space} onChange={(entity) => setSpace(entity)} />
		</div >

		<div>
			Computed LEVEL [{computedLevel.toString()}]
		</div>

	</div>
	, [space, setComputedLevel, computedLevel, autoLoad]);


	const win = useMemo(
		() => (
			<div className={"MapsWrap"}>

				{showWorld &&
				<div style={{
					position: "absolute",
						width: "100%",
						height: "100%",
				}}>
					<Leaflet
						moveEnd={moveEnd}
						position={position}
						points={points}
						newPoint={newPoint}
						deletePoint={deletePoint}
						onSelect={selected}
					/>
				</div>
				}

				<Toolbox
					tools={[
						/*
					{
						icon: "BiCube",
						state: showThree,
						onClick: () => setShowThree(!showThree),
						label: "2d / 3d",
					},
					{
						icon: "BiWorld",
						state: showWorld,
						onClick: () => setShowWorld(!showWorld),
						label: "2d / 3d",
					},
					{
						icon: "MdZoomIn",
						state: false,
						onClick: console.log,
						label: "2d / 3d",
					},
					{
						icon: "MdZoomOut",
						state: false,
						onClick: console.log,
						label: "2d / 3d",
					},
						 */
						{
							icon: newPoint ? "MdOutlineCancel" : "BsGeoAltFill" ,
							state: false,
							onClick: () => {
								//setNewPoint(!newPoint),
							},
							label: newPoint ? "Cancel new point" : "Set new point",
						},
					]}
				/>

				{/*
					showThree &&
				<div style={{
					position: "absolute",
						width: "100%",
						height: "100%",
						zIndex: 998,
				}}>
					<Three>
						<SpaceThree
							points={points}
							volume={volume}
						/>
					</Three>
				</div> 
					*/}

			</div>
		),
		[position, points, volume, moveEnd, showThree, computeLevel]
	);

	return <WindowChild tab={tab}>{win}</WindowChild>;
};

export default Maps

import * as ReactDOM from 'react-dom';
import ViewportElement from "./ViewportElement";
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';

// [REACT 2/3 init] and [FREE] (and [updateLifecycle!?]) functions
// context provider, custom hook
// wrapper that land component in the right env
// (newarly seamless as native but with threeXYetc)

class JSX2DNode extends ViewportElement {
	element: CSS2DObject;
	div: HTMLElement;
	constructor(three, jsx){
		super(three);
		this.div = document.createElement( 'div' );
		//this.div.style.pointerEvents = "auto";
	//	this.div.style.pointerEvents = "none";

		ReactDOM.render( jsx , document.body.appendChild(this.div));
		this.element = new CSS2DObject( this.div );
		this.group.add(this.element)
	}

	destruct(){
		ReactDOM.unmountComponentAtNode(this.div)
		this.group.remove(this.element);
	}
}

class JSX3DNode extends ViewportElement {
	element: CSS3DObject;
	div:HTMLElement;
	constructor(three, jsx){
		super(three);
		this.div = document.createElement( 'div' );
		//this.div.style.pointerEvents = "auto";
		this.div.style.pointerEvents = "none";

		ReactDOM.render( jsx , document.body.appendChild(this.div));
		this.element = new CSS3DObject( this.div);
		this.group.add(this.element)
	}

	destruct(){
		ReactDOM.unmountComponentAtNode(this.div)
		this.group.remove(this.element)
	}
}

export {JSX2DNode, JSX3DNode};

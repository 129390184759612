//@ts-ignore
import { notif } from "@ledr/layout";
import { FunctionComponent, useState, useEffect, useMemo, useContext } from "react";
import { Print, SidebarMenu, T_SidebarCategories, WindowChild, } from "@ledr/instruments";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store/types";
import Authorizations from "./Authorizations";
import "./Settings.scss";

let notifTest = () => {
	let rand = Math.floor(Math.random() * 4);
	if (rand === 0) {
		notif.info("Info");
	} else if (rand === 1) {
		notif.warning("Warning");
	} else if (rand === 2) {
		notif.success("Success");
	} else if (rand === 3) {
		notif.error("Error");
	}
};

interface EntityWindowTabProps {

}

interface StoreProps {}

const Settings: FunctionComponent<StoreProps> = (props) => {

	const store = useSelector((state: AppState) => state);


	const sidebarCategories: T_SidebarCategories = [
		[
			{
				icon: "MdOutlineAccountCircle",
				color: "#0ba783",
				name: "Account",
				content: (
					<>
						<div className={`shadow wrap`}>
							<div className={"headLine"}>User Info</div>
						</div>
						<div className={`shadow wrap`}>
							<div className={"headLine"}>Password</div>

							<button disabled>Change</button>
						</div>

						<div className={`shadow wrap`}>
							<div className={"headLine"}>Multi-factor authentication</div>
							<div className={"section"}>
								<label></label>
							</div>
						</div>

						<div className={"shadow wrap"}>
							<div className={"headLine"}>Sessions</div>
							<div className={"section"}>
								<label>Current sessions</label>
							</div>
							<div className={"section"}>
								<label>Past sessions</label>
							</div>
						</div>
					</>
				),
			},
			{
				icon: "BsKey",
				color: "#0ba783",
				name: "Authorizations",
				content: (
					<>

						<div className={`shadow wrap`}>
							<div className={"headLine"}>Authorizations</div>
							<div className={"section"}>
								<Authorizations />
							</div>
						</div>
					</>
				),
			},
			{
				icon: "BiPlug",
				color: "#0ba783",
				name: "Connection",
				content: (
					<>
						<div className={`shadow wrap`}>
							<div className={"headLine"}>Connection</div>
							<div className={"section"}>
								<label>Avesterra Server</label>
								[IP / Use][Add / Remove]
							</div>
						</div>
					</>
				),
			},
			{
				icon: "BiDevices",
				color: "#0ba783",
				name: "Devices",
				content: (
					<>
						<div className={`shadow wrap`}>
							<div className={"headLine"}>Main Desktop</div>
							<div className={"section"}>
								Type: Desktop
							</div>
							<div className={"section"}>
								Screen A (2540*1440) | Screen B (2540*1440) | Screen C (2540*1440)
							</div>
							<div className={"section"}>
								Allowed Panels: #578 (Admin) #132 (Meteo) #678 (News)
							</div>
						</div>

						<div className={`shadow wrap`}>
							<div className={"headLine"}>Living room</div>
							<div className={"section"}>
								Type: Smart TV
							</div>
							<div className={"section"}>
								Screen (3840*2160)
							</div>
							<div className={"section"}>
								Allowed Panels: #132 (Meteo)
							</div>
						</div>

						<div className={`shadow wrap`}>
							<div className={"headLine"}>Phone</div>
							<div className={"section"}>
								Screen (1440*2560)
							</div>
							<div className={"section"}>
								Allowed Panels: #678 (News)
							</div>
						</div>
					</>
				),
			},
			{
				icon: "MdOutlineSdStorage",
				color: "#0ba783",
				name: "Storage",
				content: (
					<>
						<div className={`shadow wrap`}>
							<div className={"headLine"}>Redux Store</div>
							<Print name={""} value={store} maxDeepness={2} />
						</div>
						<div className={`shadow wrap`}>
							<div className={"headLine"}> Local Storage </div>
							<Print name={""} value={JSON.parse(localStorage.getItem("state"))} maxDeepness={2}/>
						</div>
						<div className={`shadow wrap`}>
							<div className={"headLine"}> Cookies </div>
							<Print name={""} value={document.cookie} maxDeepness={2}/>
						</div>
					</>
				),
			},
			{
				icon: "MdOutlineTv",
				color: "#0ba783",
				name: "Interface",
				content: (<>

					<div className={`shadow wrap`}>
						<div className={"headLine"}>Interface</div>
						<div className={"section"}>
							<label>Theme</label>
							<div className={"theme"}>
								<input type="radio" name="season" value="dark" checked />
								Dark
								<input type="radio" name="season" value="light" disabled />
								Light
								<input type="radio" name="season" value="hi" disabled />
								High contrast
								<input type="radio" name="season" value="custom" disabled />
								Custom
							</div>
						</div>
						<div className={"section"}>
							<label>Notifications</label>
							<button onClick={notifTest}>Notif test</button>
						</div>
					</div>



				</>),
			},
		],
	];

	const tab = useMemo( () => (<>SETTINGS</>), []);
	const win = useMemo(() => <SidebarMenu categories={sidebarCategories} />, [store]);
	return (<WindowChild tab={tab}>
		<div className={"settingsCenter"}>
			{win}
		</div>
	</WindowChild>);
};

export default Settings;

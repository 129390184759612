import { FunctionComponent, useState, useEffect, useMemo, useContext } from "react";
import { Print, WindowChild, } from "@ledr/instruments";
import  InstrumentsLinkerContext from "../context/instrumentsLinker";
import  InstrumentsLinkerOverseerContext from "../context/instrumentsLinkerOverseer";
import './EntityLinkerDebug.scss'

interface StoreProps {}
const Store: FunctionComponent<StoreProps> = (props) => {
	const InstrumentsLinkerOverseer = useContext(InstrumentsLinkerOverseerContext); 
	const MyContext = useContext(InstrumentsLinkerContext); 
	const [nodeIn, setNodeIn] = useState();
	const [preciseNodeIn, setPreciseNodeIn] = useState();


	useEffect(()=>{
		// DECLARE HIMSELF TO UPPER STAGE
		MyContext.declareInOut(
			{
				in : [
					{port: "someInPort", setter: setNodeIn },
					{port: "preciseInPort", setter: setPreciseNodeIn },
				],
				out :[
					{port: "someOutPort" },
					{port: "preciseOutPort" },
				]
			}
		)	
		return () => { MyContext.unDeclareInOut() }
	}, [])

	const tab = useMemo( () => (<> Tab </>), []);
	const win = useMemo(() => (
		<>
			<div style={{display: "flex", flexDirection: "row"}}>
				<div
					style={{
						margin: "10px",
						padding: "10px",
						width: "100%",
						borderRadius: "20px",
						border: "1px solid red",
					}}
				>
					<h2>GLOBAL</h2>
					<Print value={{
							ports: InstrumentsLinkerOverseer.allPorts,
							links: InstrumentsLinkerOverseer.allLinks
						}} maxDeepness={4} />
					<hr/>
				</div>

				<div
					style={{
						margin: "10px",
						padding: "10px",
						width: "100%",
						borderRadius: "20px",

						border: "1px solid green",
					}}
				>
					<h2>LOCAL</h2>
					<Print value={{
						MyContext
						}} maxDeepness={100} />
				</div>
			</div>

			<div>
				{/* LOCAL IN | DISTANT OUT */}
				DATA_RECEIVED : <Print value={nodeIn}/>
				<br/>
				DATA_RECEIVED : <Print value={preciseNodeIn}/>
				<hr/>

				{/* LOCAL OUT | DISTANT IN */}
				<button onClick={() =>
					{
						//MyContext.out["SelectedEntity"](`something random : ${Math.random().toString()}`)
						//MyContext?.allPorts?.[1]?.linkIn?.[0]?.setter?.(`something random : ${Math.random().toString()}`)
						//	MyContext.p.wmEvents.newFloatWindow({component:"EntityLinkerDebug"});

						MyContext.out?.["someOutPort"]?.(Math.random().toString())
						//MyContext.out?.[0].portIn[0].setter(Math.random().toString())
					}
					}
				>
					SEND DATA
				</button>

				<button onClick={() =>
					{
						MyContext.out?.["preciseOutPort"]?.(`hello from precise port ${ Math.random().toString() }`)
					}
					}
				>
					SEND DATA PRECISE
				</button>
			</div>
		</>
	), [MyContext, nodeIn, preciseNodeIn ]);


	return <WindowChild tab={tab}>{win}</WindowChild>;
};

export default Store;

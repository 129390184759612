import { FunctionComponent, useState, useEffect, useContext, useMemo, } from "react";
//@ts-ignore
import Avial from "@ledr/ts-client";
import { SearchSelect , EntityInput,WindowChild, wmContext } from "@ledr/instruments";
import  InstrumentsLinkerContext from "../context/instrumentsLinker";
import { History, Location } from "history";
import { useHistory, useLocation } from "react-router-dom";
import ApiContext from "../context/api";
import  useEntity  from "../hook/entity";

import { Icon} from "@ledr/instruments";
import Properties from "../components/avialModel/Properties";
import Facts from "../components/avialModel/Facts";
interface CreateEntityProps {}

const CreateEntity: FunctionComponent<CreateEntityProps> = (props) => {


	const wm = useContext(wmContext); 


	const MyContext = useContext(InstrumentsLinkerContext); 
	const api = useContext(ApiContext);
	let history: History = useHistory();
	let location: Location = useLocation();

	const [registry, setRegistry] = useState(new Avial.Values.V_Entity("<25>"));
	//@ts-ignore
	const [entity, setEntity] = useState(new Avial.Values.V_Entity(wm.win.data?.entity ?? "<0>"));

	const [opts, setOpts] = useState( {
		//@ts-ignore
		newEntity: wm.win.data?.entity ? false : true,
	});
	
	const entityStore = useEntity(entity);


	let [error, setError] = useState(null);
	let [context, setContext] = useState("");
	let [name, setName] = useState("");
	let [key, setKey] = useState("");
	let [klass, setKlass] = useState("");
	let [category, setCategory] = useState("");

	let [value, setValue] = useState(entityStore.contents || new Avial.AvialEntity("{}"));
	useEffect(()=>{
		setValue( entityStore.contents || new Avial.AvialEntity("{}")
			)
		setName(entityStore.contents?.Name ?? "")
		setKey(entityStore.contents?.Key ?? "")

		setCategory(
			Object.entries(Avial.Taxonomy.Category)?.find?.(
				([k,v]) =>
				v ===  entityStore?.metadata?.Fields?.Category 
			)?.[0] ?? ""
		)
		setKlass(
			Object.entries(Avial.Taxonomy.Class)?.find?.(
				([k,v]) =>
				v ===  entityStore?.metadata?.Fields?.Class
			)?.[0] ?? ""
		)
		setContext(
			Object.entries(Avial.Taxonomy.Context)?.find?.(
				([k,v]) =>
				v ===  entityStore?.metadata?.Fields?.Context
			)?.[0] ?? ""
		)

	}, [entityStore.contents])

	const changeValue = (value) => {
		try {
			let v =  new Avial.AvialEntity(value);
			setValue(v)
			setError(null);
		} catch (e) {
			setValue(value);
			setError(e);
		}
	};

	const onSubmit = async () => {
		value.Name = name;
		value.Key = key;

		if (!error) {

		if (opts.newEntity) {
			let response = await api.createEntity({
					registry: registry,
					metadata: {
						context: context,
						class: klass,
						category: category,
						name: name,
						key: key,
					},
					content:value
				})
				let isStored = await api.storeEntity(response, value);
				let isRegistered = await api.registerEntity(
					registry,
					response,
					name,
					key
				);

				// CACHE REFRESH
				await api.get(response)
				await api.get(registry)

			} else {

				await api.purgeEntity(entity);
				await api.storeEntity(entity, value);
				await api.changeEntity(entity, {
								name: name,
								key: key,
								class: klass,
								category: category,
								context: context,
				})
				// CACHE REFRESH
				await api.get(entity)
			}
			MyContext?.out?.["THEN"]?.()
		}
	};


	///////////////////////////////////////////////////
	useEffect(()=>{
		MyContext.declareInOut( {
			in : [
				{port: "entity", setter: setEntity},
				{port: "registry", setter: setRegistry},
			],
			out :[
				{port: "THEN", type: "cb" },
			]
		})
		return () => { MyContext.unDeclareInOut() }
	}, [])
	///////////////////////////////////////////////////

	const tab = useMemo(() => <>

				<div style={{display:"flex"}}>

				<button
					style={{ marginRight: "10px", float: "right" }}
					onClick={()=>{setOpts({...opts, ["newEntity"]: true})}}
					className={!opts.newEntity ? "" : "active"}
				>
					CREATE
				</button>

				<button
					style={{ marginRight: "10px", float: "right" }}
					onClick={()=>{setOpts({...opts, ["newEntity"]: !opts.newEntity})}}
					className={opts.newEntity? "" : "active"}
				>
					EDIT
				</button>

				<div style={{display: "flex"}}>
					{opts.newEntity 
						?
							<>
								INTO
							<EntityInput value={registry} onChange={setRegistry} />
							<button style={{ padding: "3px" }} >
								<Icon
									name={"BiTargetLock"}
									size={16}
								/>
							</button>

								{/*
								LOCUTOR TO STORE APPEND INTO [Property,FACT,Value,Facet,feature]
									*/}
							</>
						:
							<>
								FROM
						<EntityInput value={entity} onChange={setEntity} />
							</>
					}
				</div>
				</div>
		<button
			style={{ marginRight: "10px", float: "right" }}
			onClick={onSubmit}
			disabled={error ? true : false}
		>
		EXECUTE
		</button>
	</>, [registry, onSubmit]);

	const win = useMemo(
		() => (
			<div className="AvialEntity">

				{ /*
				<hr/>
					TODO
				<br/>
				LOAD cat/class/context
				<br/>
				DISPLAY LABEL FOR cat/class/context
				<hr/>
					 */ }

				<div style={{display:"flex", gap: "5px", padding:"3px 3px" }}>
					<input
						type="text"
						style={{
							width: "100%",
							height: "22px",
						}}
						value={name}
						onChange={(e) => setName(e.target.value)}
						placeholder="Name"
					/>

					<input
						type="text"
						style={{
							width: "100%",
							height: "22px",
						}}
						value={key}
						onChange={(e) => setKey(e.target.value)}
						placeholder="Key"
					/>
				</div>
				<div style={{display:"flex", gap: "5px", padding:"0px 5px" }}>
					<SearchSelect
						value={category}
						list={Avial.Taxonomy.Category}
						onChange={setCategory}
						placeholder={"category"}
					/>

					<SearchSelect
						value={klass}
						list={Avial.Taxonomy.Class}
						onChange={setKlass}
						placeholder={"class"}
					/>

					<SearchSelect
						value={context}
						list={Avial.Taxonomy.Context} //Avial.Taxonomy.ContextStringEnum}
						onChange={setContext}
						placeholder={"context"}
					/>
				</div>


				<Facts
					value={value?.Facts}
					onChange={ (facts)=>{
						console.log(value, facts)
						let newValue = new Avial.AvialEntity(JSON.stringify(value));
						newValue.Facts = facts;
						setValue(newValue)
					}
					}
				/>

				<Properties
					value={value?.Properties}
					onChange={ (properties)=>{
						let newValue = new Avial.AvialEntity(JSON.stringify(value));
						newValue.Properties = properties;
						setValue(newValue)
					}
					}
				/>
				{/*
			{

				<textarea
					value={JSON.stringify(value)}
					onChange={(e) => {
						changeValue(e.target.value);
					}}
					style={{
						color: error ? "red" : "",
							height: "300px",
							width: "calc(100% - 20px)",
							padding: "10px",
							margin: "10px",
							pointerEvents: "auto",
					}}
				/>

			 <Print value={value} maxDeepness={10} />
			}
				{error && (
					<div
						style={{
							margin: "10px",
								color: "red",
						}}
					>
						char: {error.at} error: {error.message}
					</div>
				)}
				*/}
			</div>
		),
		[registry, name, key, klass, category, context, value, location.search, MyContext, opts ]
	);

	return <WindowChild tab={tab}>{win}</WindowChild>;
};

export default CreateEntity;
